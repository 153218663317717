import axios from './axios'

const methods = [
  {
    name: 'get',
    body: false
  },
  {
    name: 'delete',
    body: false
  },
  {
    name: 'post',
    body: true
  },
  {
    name: 'put',
    body: true
  }
]
const api = {}

methods.forEach(({ name, body }) => {
  api[name] = (option) => axios({ method: name, ...option, params: !body ? (option.data || {}) : null, data: body ? (option.data || {}) : null })
})

export default api
