export default [
  {
    path: '/doctor/home',
    name: 'doctor-home',
    component: () => import('@/views/doctor/home'),
    meta: {
      title: '医生主页'
    }
  },
  {
    path: '/doctor/list',
    name: 'doctor-list',
    component: () => import('@/views/doctor/list'),
    meta: {
      title: '医生列表'
    }
  },
  {
    path: '/doctor/inquiry',
    name: 'doctor-inquiry',
    component: () => import('@/views/doctor/inquiry'),
    meta: {
    }
  },
  {
    path: '/doctor/comments',
    name: 'doctor-comments',
    component: () => import('@/views/doctor/comments'),
    meta: {
      title: '全部评价'
    }
  },
  {
    path: '/doctor/labour',
    name: 'doctor-labour',
    component: () => import('@/views/doctor/labour'),
    meta: {
      title: '医疗风险告知及知情同意书'
    }
  },
  {
    path: '/doctor/rights-list',
    name: 'doctor-rights-list',
    component: () => import('@/views/doctor/rights-list'),
    meta: {
      title: '医生列表'
    }
  }
]
