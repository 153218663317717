<template>
  <div class="vip-wrap" v-if="config.memberCode">
    <!-- memberFlag  会员状态（0未购买，1已开通）-->
    <img class="vip-card" :src="config.memberBackgroundImage || require('@/assets/vip-card.png')">
    <div class="vip-top" @click="onJumpVip">
      <div class="vip-top_left">
        <div class="left-title z-line1">{{ config.memberName }}</div>
        <div class="left-subtitle" v-if="config.memberFlag==1">有效期至 {{ config.expireTime }}</div>
        <div class="left-subtitle z-line2" v-else>{{ config.discountInfo }}</div>
      </div>
      <div class="vip-top_right" v-if="showBuy && config.memberFlag==0">立即开通</div>
      <div class="vip-top_right" v-if="showCenter && config.memberFlag==1">会员中心</div>
      <div class="vip-top_right" v-if="showRenew">立即续费</div>
    </div>
    <div class="vip-split"></div>
    <div class="vip-bottom">
      <div class="vip-bottom_box box-left" @click="onJumpCoupon(1)">我的券包 <span class="vip-bottom_box-num">{{ config.couponCount>99?'99+':config.couponCount }}</span></div>
      <div class="vip-bottom_box box-center" @click="onJumpCoupon(0)">我的权益 <span class="vip-bottom_box-num">{{ config.interestCount>99?'99+':config.interestCount }}</span></div>
      <div class="vip-bottom_box box-right" @click="onJumpManage">我的管理组 <span class="vip-bottom_box-num">{{ config.manageCount>99?'99+':config.manageCount }}</span></div>
    </div>

  </div>
</template>

<script>
import api from '@/utils/api'

export default {
  name: 'ZVipCard',

  props: {
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 会员中心按钮显隐
    showCenter: {
      type: Boolean,
      default: true
    },
    // 立即购买按钮显隐
    showBuy: {
      type: Boolean,
      default: true
    },
    // 立即续费按钮显隐
    showRenew: {
      type: Boolean,
      default: false
    }
  },

  methods:{
    // 跳转会员购买
    onJumpVip(){
      if(this.config.memberFlag == 0){
        api.jumpPage('vip-buy', {
          memberCode: this.config.memberCode
        })
      }
      if(this.config.memberFlag == 1){
        api.jumpPage('vip-center', {
          memberCode: this.config.memberCode
        })
      }
    },

    // 跳转券包
    onJumpCoupon(item){
      api.jumpPage('my-coupon', {
        active: item
      })
    },

    // 跳转管理组
    onJumpManage(){
      api.jumpPage('manage-list')
    }
  }
}
</script>

<style lang="scss" scoped>
.vip-wrap {
  width: 690px;
  height: 220px;
  position: relative;
  margin: 30px;

  .vip-card {
    width: 690px;
    height: 220px;
    object-fit: cover;
  }

  .vip-top {
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    padding: 24px 20px;

    .vip-top_left {
      width: 505px;
      padding-right: 10px;

      .left-title {
        font-weight: 800;
        font-size: 42px;
        color: #3E1D16;
      }

      .left-subtitle {
        font-weight: bold;
        font-size: 22px;
        color: #66483A;
        margin-top: 3px;
      }
    }

    .vip-top_right {
      flex: none;
      width: 150px;
      height: 60px;
      background: #3E1D16;
      border-radius: 30px 30px 30px 30px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 800;
      font-size: 22px;
      color: #FCEEE1;
      margin-top: 16px;
    }
  }

  .vip-split {
    position: absolute;
    top: 160px;
    left: 0;
    width: 650px;
    height: 0px;
    border: 1px solid #3E1D16;
    opacity: 0.06;
    margin: 0 20px;
  }

  .vip-bottom {
    position: absolute;
    top: 160px;
    left: 0;
    width: 650px;
    margin: 15px 20px;
    display: flex;
    align-items: center;

    .vip-bottom_box {
      flex: 1;
      font-size: 22px;
      color: #3E1D16;
    }

    .vip-bottom_box-num {
      font-weight: bolder;
    }

    .box-left {
      text-align: left;
    }

    .box-center {
      text-align: center;
    }

    .box-right {
      text-align: right;
    }
  }
}
</style>
