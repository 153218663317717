export default [
  {
    path: '/dept/list',
    name: 'dept-list',
    component: () => import('@/views/dept/list'),
    meta: {
      title: '科室推荐'
    }
  },
  {
    path: '/dept/index',
    name: 'dept-index',
    component: () => import('@/views/dept/index'),
    meta: {
      title: '科室详情'
    }
  }
]
