export default [
  {
    path: '/genetictest/test-self',
    name: 'genetictest-test-self',
    component: () => import('@/views/genetictest/test-self/index'),
    meta: {
      title: '量表'
    }
  },
  {
    path: '/genetictest/gene-test',
    name: 'genetictest-gene-test',
    component: () => import('@/views/genetictest/gene-test/index'),
    meta: {
      title: '评估'
    }
  },
  {
    path: '/genetictest/gene-patienr',
    name: 'genetictest-gene-patienr',
    component: () => import('@/views/genetictest/gene-patienr/index'),
    meta: {
      title: '选择就诊人'
    }
  },
  {
    path: '/genetictest/text-result',
    name: 'genetictest-text-result',
    component: () => import('@/views/genetictest/text-result/index'),
    meta: {
      title: '结果'
    }
  },
  {
    path: '/genetictest/text-record',
    name: 'genetictest-text-record',
    component: () => import('@/views/genetictest/text-record/index'),
    meta: {
      title: '测评结果'
    }
  }
]
