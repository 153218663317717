<template>
  <div class="wrap-coupon">
    <div class="coupon-m">
      <div class="coupon-m_left">
        <template v-if="showUse">
          <div class="coupon-m_left-block1" v-if="isUsed">已用{{dataCoupon.usedCount+'张'}}</div>
          <div class="coupon-m_left-block1" v-else>{{dataCoupon.totalCount==null?'无限次':'剩余次数'+dataCoupon.remainCount}}</div>
        </template>
<!--        {{dataCoupon.usedCount+'/'+dataCoupon.totalCount}}-->
        <template v-else>
          <div class="coupon-m_left-block1" v-if="dataCoupon.totalCount==null">无限次</div>
          <div class="coupon-m_left-block1" v-else>剩余次数{{dataCoupon.remainCount}}</div>
        </template>

        <!-- 权益类型(0权益，1优惠) -->
        <!-- discountType：优惠类型(1折扣，2面值，3免费) -->
        <div class="coupon-m_left-block2">
          <div class="block2-price"><span class="price-tag" v-if=" dataCoupon.discountType == 2 ">¥</span>{{dataCoupon.couponContent}}</div>
          <div class="block2-row2">{{ dataCoupon.couponLimit || '无门槛' }}</div>
          <div class="block2-row3" v-if="dataCoupon.highestDiscount">最高抵扣{{dataCoupon.highestDiscount}}元</div>
        </div>
      </div>
      <div class="coupon-m_right">
        <div class="coupon-m_right-row1"><van-icon size="15" v-if="showDescIcon && dataCoupon.interestsDesc" name="question-o" @click.stop="onShowDesc"/></div>
        <div class="coupon-m_right-row2 z-line1">{{dataCoupon.interestsName}}</div>
        <div class="flex">
          <div class="flex-left">
            <div class="coupon-m_right-row3"><span class="z-line1">{{dataCoupon.interestsSubName}}</span></div>
            <div class="coupon-m_right-limit" v-if="dataCoupon.patientId">仅限{{dataCoupon.patientName}}使用</div>
            <div class="coupon-m_right-row4" :class="dataCoupon.patientId?'':'mart10'">
              <!-- withinSevenDay 七天内标识(0否，1是) -->
              <span class="row4-left">{{dataCoupon.expireTime}}到期 <span class="row4-expired" v-if="dataCoupon.withinSevenDay==1">即将失效</span></span>
            </div>
          </div>
          <div class="flex-right use-btn" v-if="!showCheck">
            <!--  expireFlag 是否已过期（0未过期，1已过期） -->
            <div class="use-btn_grey" v-if="isUsed"><span >已使用</span></div>
            <div class="use-btn_grey" v-else-if="isFailed"><span >已失效</span></div>
            <div class="use-btn_use" v-else-if="!isUsed&&!isFailed" @click="onJumpUse"><span >立即使用</span></div>
          </div>

          <div class="flex-right" v-if="showCheck">
            <div class="check-icon">
              <van-icon
                name="checked"
                color="#FE6F02"
                size="21"
                v-if="isCheck"
              />
              <span
                class="check-icon_circle"
                v-else
              ></span>
            </div>
          </div>
        </div>
      </div>
    </div>
    <van-action-sheet
      v-model="showDesc"
      safe-area-inset-bottom
      close-on-click-overlay
      title="使用说明"
      @close="onHideDesc"
    >
      <div class="rich-text-box" v-html="colRichText"></div>
    </van-action-sheet>
    <van-dialog id="van-dialog" />

  </div>

</template>

<script>
import api from '@/utils/api'
import { getWriteTextMini } from '@/utils/utils'
import { Dialog } from 'vant'

export default {
  name: 'ZCouponItem',

  props: {
    dataCoupon: {
      type: Object,
      default: () => ({})
    },
    isCheck: {
      type: Boolean,
      default: false
    },
    isExpired: {
      type: Boolean,
      default: false
    },
    isFailed: {
      type: Boolean,
      default: false
    },
    isUsed: {
      type: Boolean,
      default: false
    },
    showCheck: {
      type: Boolean,
      default: false
    },
    showDescIcon: {
      type: Boolean,
      default: true
    },
    showUse: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      showDesc: false,
      colRichText: '' // 富文本
    }
  },

  watch: {
  },

  methods: {
    // 立即使用
    onJumpUse(){
      let type = this.dataCoupon.interestsType
      if(type == 2){
        api.jumpPage('my-imgview', {
          imageUrl: this.dataCoupon.assistantDetailImage,
          title: '健康助手'
        })
      } else {
        api.jumpLink({
          linkType: 0,
          linkSrc: this.dataCoupon.writeOffUrl
        })
      }
    },

    // 显示使用说明
    onShowDesc(){
      // this.showDesc = true
      this.colRichText = getWriteTextMini(this.dataCoupon.interestsDesc)

      Dialog.alert({
        title: '服务说明',
        context: this,
        theme: 'round-button',
        messageAlign: 'left',
        confirmButtonText: '我知道了',
        className: 'dialog_notice',
        message: this.colRichText,
        confirmButtonColor: '#4085D5'
      }).then(() => {
        // on close
      })
    },

    onHideDesc(){
      this.showDesc = false
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .van-action-sheet__header {
    font-weight: bold;
    font-size: 38px;
    color: #353535;
    text-align: left;
    padding-left: 30px;
  }

}

.rich-text-box {
  max-width: 100%;
  display: block;
  white-space: pre-wrap;
  word-break: break-all;
  min-height: 200px;
}

.coupon-m {
  width: 690px;
  min-height: 220px;
  background: linear-gradient( 90deg, #FFF9F1 0%, #FFEEE7 100%);
  display: flex;
  margin-bottom: 30px;
  border-radius: 16px;

  .flex {
    flex: 1;
    display: flex;
    justify-content: space-between;

    .flex-left {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
    }

    .flex-right {
      flex: none;
      display: flex;
      align-items: start;
      margin-left: 10px;
    }
  }

  .coupon-m_left::after {
    content: ''; /* 必须设置 */
    position: absolute; /* 绝对定位 */
    right: 0; /* 紧贴在元素右侧 */
    top: 10px; /* 从元素顶部开始 */
    bottom: 10px; /* 通过调整bottom的值来“调整”边框的高度 */
    width: 1px; /* 边框宽度 */
    border-left: 1px dashed rgba(183,162,158,0.27); /* 边框颜色 */
  }

  .coupon-m_left {
    width: 202px;
    position: relative;

    .coupon-m_left-block1 {
      position: absolute;
      top: -10px;
      left: 0;
      min-width: 150px;
      height: 52px;
      background: #FCD9B6;
      border-radius: 28px 28px 28px 0px;
      display: flex;
      align-items: center;
      font-size: 24px;
      color: #3E1D16;
      padding: 0 20px;
    }

    .coupon-m_left-block2 {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;

      .block2-price {
        font-weight: 800;
        font-size: 60px;
        color: #FE1F02;
        display: flex;
        justify-items: flex-start;
        margin-top: 30px;
      }

      .price-tag {
        font-size: 40px;
        margin-right: 10px;
        padding-top: 6px;
      }

      .block2-row2 {
        font-size: 28px;
        color: #FE1F00;
        margin-top: 5px;
      }

      .block2-row3 {
        margin-top: 3px;
        font-size: 24px;
        color: #A89B96;
      }
    }

  }

  .coupon-m_right {
    flex: 1;
    padding: 10px 20px 20px 20px;
    display: flex;
    flex-direction: column;

    .coupon-m_right-row1 {
      font-size: 24px;
      color: #ABABAB;
      text-align: right;
      height: 30px;
    }
    .coupon-m_right-row2 {
      font-weight: 800;
      font-size: 32px;
      color: #3E1D16;
    }

    .coupon-m_right-row3 {
      font-size: 24px;
      color: #C1B9B0;
      height: 36px;
      margin-top: 5px;
    }

    .coupon-m_right-limit {
      font-size: 24px;
      color: #C1B9B0;
    }

    .use-btn {
      flex: none;
      margin-left: 5px;
    }

    .use-btn_use {
      background: linear-gradient( 90deg, #FE1F02 0%, #FE6F02 100%);
      width: 136px;
      height: 54px;
      border-radius: 27px 27px 27px 27px;
      font-size: 24px;
      color: #FCEEE1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .use-btn_grey {
      width: 133px;
      height: 46px;
      border-radius: 23px 23px 23px 23px;
      border: 1px solid #ABABAB;
      font-size: 24px;
      color: #ABABAB;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .coupon-m_right-row4 {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 26px;
      color: #C1B9B0;

      .row4-left {
        display: flex;
        align-items: end;
      }

      .row4-expired {
        font-size: 22px;
        color: #FF1E02;
        margin-left: 12px;
      }

      .row4-btn {
        flex: none;
        display: flex;
        align-items: end;
        margin-left: 5px;
      }

      .row4-btn_use {
        background: linear-gradient( 90deg, #FE1F02 0%, #FE6F02 100%);
        width: 136px;
        height: 54px;
        border-radius: 27px 27px 27px 27px;
        font-size: 24px;
        color: #FCEEE1;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .row4-btn_grey {
        width: 133px;
        height: 46px;
        border-radius: 23px 23px 23px 23px;
        border: 1px solid #ABABAB;
        font-size: 24px;
        color: #ABABAB;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

  }

  .row5-expire {
    width: 133px;
    height: 46px;
    border-radius: 23px 23px 23px 23px;
    border: 1px solid #FF1E02;
    font-size: 24px;
    color: #FF1E02;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .check-icon {

  }

  .check-icon_circle {
    display: inline-block;
    width: 36px;
    height: 36px;
    border: 1px solid #707070;
    border-radius: 50%;
  }
}
</style>
