<template>
  <!-- 儿童组件 -->
  <div class="child-wrap">
    <div
      class="child"
      @click="onJumpAddChild"
      v-if="config.childList.length==0||config.childList == null"
    >
      <div class="child-left">
        <div class="child-left_img">
          <img
            class="img"
            :src="require('@/assets/avatar.png')"
          />
        </div>
        <div class="child-left_info">
          <div class="title">您还未创建儿童档案</div>
          <div class="subtitle">快来测测宝宝成长的健康情况！</div>
        </div>
      </div>
      <div class="child-btn">去创建</div>
    </div>
    <van-swipe
      circular
      autoplay="5000"
      v-else
      class="child-swiper"
      :show-indicators="false"
      ref="swiper"
    >
      <van-swipe-item
        v-for="(itemSub, index) in config.childList"
        :key="index"
        @click="onJumpFile(itemSub)"
      >
        <div class="child-swiper_item">
          <div
            class="child-box"
            :class="itemSub.childGender=='2'?'child-girl':'child-boy'"
          >
            <div class="child-box_left">
              <div class="info">
                <div class="name z-line1">{{itemSub.childName}}</div>
                <div class="age">{{itemSub.childAge}}岁</div>
                <div><img
                    class="sex"
                    :src="itemSub.genderIcon"
                  /></div>
              </div>
              <div class="card">
                <div class="card-row">
                  <div class="card-row_left">
                    <div class="card-row_title">身高</div>
                    <div
                      class="card-row_value"
                      v-if="itemSub.testHeight==null"
                    >暂无</div>
                    <div
                      v-else
                      class="flex-baseline"
                    >
                      <div class="card-row_value">{{itemSub.testHeight}}</div>
                      <div class="card-row_unit">CM</div>
                    </div>
                  </div>
                  <div
                    class="card-row_label z-line1"
                    :class="`label-height-${itemSub.growLevel}`"
                  >{{itemSub.heightResult}}</div>
                </div>
                <div class="card-row">
                  <div class="card-row_left">
                    <div class="card-row_title">体重</div>
                    <div
                      class="card-row_value"
                      v-if="itemSub.testWeight==null"
                    >暂无</div>
                    <div
                      v-else
                      class="flex-baseline"
                    >
                      <div class="card-row_value">{{itemSub.testWeight}}</div>
                      <div class="card-row_unit">KG</div>
                    </div>
                  </div>
                  <div
                    class="card-row_label z-line1"
                    :class="`label-height-${itemSub.weightLevel}`"
                  >{{itemSub.weightResult}}</div>
                </div>
                <div class="card-row">
                  <div class="card-row_left">
                    <div class="card-row_title marr27">BMI</div>
                    <div
                      class="card-row_value"
                      v-if="itemSub.bmiValue==null"
                    >暂无</div>
                    <div
                      class="card-row_value"
                      v-else
                    >{{itemSub.bmiValue}}</div>
                  </div>
                  <div
                    class="card-row_label z-line1"
                    :class="`label-height-${itemSub.bmiLevel}`"
                  >{{itemSub.bmiResult}}</div>
                </div>
              </div>
            </div>
            <div class="child-box_right">
              <img
                class="img"
                :src="itemSub.cartoonImage"
              />
            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>

  </div>
</template>

<script>

import api from '@/utils/api'
import auth from '@/utils/auth'

export default {
  props: {
    // 配置信息
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {

    }
  },

  methods: {
    // 跳转 linkType: 0内部路由｜1外部链接
    onJumpLink(item) {
      api.jumpLink(item)
    },

    // 跳转-添加儿童
    onJumpAddChild() {
      if (this.$store.getters.getToken) {
        api.jumpPage('kids-child-add', {
          ...this.config
        })
      } else {
        this.onJumpLogin()
      }
    },

    // 跳转-身高档案
    onJumpFile(item) {
      api.jumpPage('kids-growth-record', {
        childId: item.childId
      })
    },

    // 登录
    async onJumpLogin() {
      try {
        const info = await auth() || {}
        if (info.url) {
          // 有回调地址的话直接强制重定向
          window.location.replace(info.url)
        } else {
          // 正常进入页面，用于直接通过openId进行登录
          this.$router.go(0)
        }
      } catch (err) {
        if (err?.url) {
          // 强制重定向页面的话
          window.location.replace(err.url)
        } else {
          // 走手机号登录流程
          await this.$router.replace({
            path: '/login'
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* 儿童模块 */
.child-wrap {
  border-radius: 16px 16px 16px 16px;
  margin: 30px 30px;
}

.child {
  width: 690px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 150px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 16px 16px 16px 16px;
}

.child-left {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.child-left_img {
  flex: none;
  position: relative;
  margin-right: 20px;
}

.child-left_img .img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
}

.child-left_info {
}

.child-left_info .title {
  color: #353535;
  font-size: 36px;
  font-weight: bold;
}

.child-left_info .subtitle {
  margin-top: 5px;
  font-size: 28px;
  color: #ababab;
}

.child-btn {
  flex: none;
  font-size: 28px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 50px;
  background: #03cad8;
  border-radius: 10px 10px 10px 10px;
}

.child-swiper {
  height: 384px;
}

.child-swiper_item {
  margin-bottom: 30px;
}

.child-box {
  display: flex;
  flex-direction: row;
  height: 384px;
  border-radius: 16px 16px 16px 16px;
}

.child-girl {
  background: #fef0f5;
  border: 1px solid #fed8d8;
}

.child-boy {
  background: #e4f2ff;
  border: 1px solid #b4daff;
}

.child-box_left {
  width: 390px;
  padding-right: 10px;
}

.child-box_left .info {
  display: flex;
  align-items: baseline;
  padding-top: 45px;
  padding-left: 40px;
}

.child-box_left .name {
  color: #353535;
  font-size: 42px;
  font-weight: bolder;
}

.child-box_left .age {
  flex: none;
  font-size: 28px;
  color: #ababab;
  margin-left: 10px;
}

.child-box_left .sex {
  flex: none;
  width: 32px;
  height: 33px;
  margin-left: 10px;
  vertical-align: bottom;
  //margin-bottom: 2px;
}

.child-box_left .card {
  max-width: 380px;
  height: 214px;
  background: #ffffff;
  border-radius: 16px 16px 16px 16px;
  margin-top: 20px;
  margin-left: 20px;
  padding: 20px 15px 20px 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.marr27 {
  margin-right: 27px !important;
}

.flex-baseline {
  display: flex;
  align-items: baseline;
}

.child-box_left .card .card-row {
  display: flex;
  align-items: center;
}

.child-box_left .card .card-row_left {
  width: 70%;
  display: flex;
  align-items: center;
  padding-right: 10px;
}

.child-box_left .card .card-row_title {
  flex: none;
  font-size: 30px;
  color: #353535;
  margin-right: 20px;
}

.child-box_left .card .card-row_value {
  font-weight: bold;
  font-size: 36px;
  color: #353535;
  margin-right: 10px;
}

.child-box_left .card .card-row_unit {
  font-size: 25px;
  color: #ababab;
  margin-right: 10px;
}

.child-box_left .card .card-row_label {
  flex: 1;
  height: 29px;
  border-radius: 15px 15px 15px 0px;
  text-align: center;
  color: #ffffff;
  font-size: 20px;
  padding: 0 5px;
  margin-left: 5px;
}

.label-height-0,
.label-weight-0,
.label-bmi-0 {
  background: #316cf5;
}

.label-height-1 {
  background: #5babf7;
}

.label-height-2,
.label-weight-1,
.label-bmi-1 {
  background: #31c8b4;
}

.label-height-3,
.label-bmi-2 {
  background: #f1ab51;
}

.label-height-4,
.label-weight-2,
.label-bmi-3 {
  background: #ed655e;
}

.child-box_right {
  flex: none;
  width: 300px;
  padding: 15px 0;
}

.child-box_right .img {
  width: 300px;
  height: 100%;
}
</style>
