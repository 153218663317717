<template>
  <div>
    <van-action-sheet
      v-model="show"
      safe-area-inset-bottom
      close-on-click-overlay
      title="我的优惠券"
      @close="close"
    >
      <z-empty
        v-if="!arrCoupon?.length"
        title="您还没有优惠券"
        :src=" require('@/assets/empty_coupon.png')"
      ></z-empty>
      <div
        class="other-patienter-box"
        v-else
      >
        <div class="coupon-box">
          <div
            class="coupon-item"
            v-for="(item,index) in arrCoupon"
            :key="index"
            @click="selectCoupon(item,index)"
          >
            <div class="coupon-item-top">
              <div class="coupon-discount">
                <div class="coupon-discount-amount">{{item.couponContent}}</div>
                <div
                  class="coupon-door"
                  v-if="item.couponLimit"
                >{{item.couponLimit}}</div>
              </div>
              <div class="coupon-content">
                <div class="coupon-content-title">{{item.interestsSubName}}</div>
                <div class="coupon-content-info">{{item.interestsDescription}}</div>
                <div class="coupon-content-info">有效日期：{{item.expireTime}}</div>
              </div>
              <div class="coupon-select">
                <van-icon
                  name="checked"
                  color="#03CAD8"
                  size="18"
                  v-if="item.isCheck"
                />
                <span
                  class="item-circle"
                  v-else
                ></span>
              </div>
            </div>
            <div class="coupon-item-bottom">
              <div class="item-bottom-left">{{item.interestsName}}</div>
              <div class="item-bottom-right">剩余次数： {{item.remainCount=='不限'?item.remainCount+'次':item.remainCount+' 次'}}</div>
            </div>
            <div class="coupon-circle-left"></div>
            <div class="coupon-circle-right"></div>
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>

export default {
  name: 'ZCoupon',

  props: {
    // 是否展示优惠券列表
    showValue: {
      type: Boolean,
      default: false
    },
    arrCoupon: {
      type: Array,
      default: () => ([])
    }
  },

  data() {
    return {
      show: false,
      dataCurrentCoupon: {}
    }
  },

  watch: {
    showValue: {
      handler(newValue) {
        // console.log(newValue)
        this.show = newValue
      }
    }
  },

  methods: {
    // 选中优惠券
    selectCoupon(item, index) {
      let selectCoupon = this.arrCoupon[`${index}`]
      if (selectCoupon.isCheck) {
        selectCoupon.isCheck = false
        this.$emit('selectedCoupon', {})
        this.dataCurrentCoupon = selectCoupon
      } else {
        this.arrCoupon.forEach((item, indx) => {
          item.isCheck = false
        })
        selectCoupon.isCheck = true
        this.$emit('selectedCoupon', selectCoupon)
        this.dataCurrentCoupon = selectCoupon
      }

      this.show = false
      // eslint-disable-next-line vue/no-mutating-props
    },

    // 关闭弹窗
    close() {
      this.$emit('close')
    }
  }

}
</script>

<style lang="scss" scoped>
.other-patienter-box {
  box-sizing: border-box;
  background: #f9f9f9;
  overflow-y: scroll;
  padding: 30px;
  height: 600px;
}
.coupon-title {
  font-size: 36px;
  font-weight: bold;
  line-height: 50px;
  color: #222222;
  text-align: center;
  padding: 40px 0;
}
.coupon-item {
  box-shadow: 0 3px 12px rgba(181, 181, 181, 0.16);
  background: white;
  padding: 0 21px;
  border-radius: 6px;
  position: relative;
  margin-bottom: 30px;
}
.coupon-item-top {
  display: flex;
  align-items: top;
  min-height: 164px;
  padding: 30px 0;
}
.coupon-item-bottom {
  height: 60px;
  font-size: 22px;
  color: #666666;
  border-top: 1px dashed #efefef;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.coupon-circle-left,
.coupon-circle-right {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #f9f9f9;
  position: absolute;
}
.coupon-circle-left {
  left: -16px;
  bottom: 52px;
}
.coupon-circle-right {
  right: -16px;
  bottom: 52px;
}
.coupon-discount {
  color: #f82e2d;
  margin-right: 30px;
  flex: none;
}
.coupon-discount-amount {
  font-size: 60px;
  font-weight: bold;
  text-align: center;
}
.coupon-door {
  font-size: 26px;
  font-weight: 400;
  text-align: center;
}
.coupon-content {
  flex: auto;
}
.coupon-content-title {
  font-size: 36px;
  font-weight: bold;
  line-height: 50px;
  color: #353535;
  margin-bottom: 5px;
}
.coupon-content-info {
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
  color: #ababab;
}
.coupon-select {
  flex: none;
  margin-left: 10px;
}
.item-dot {
  display: inline-block;
  width: 32px;
  height: 32px;
  background: #54BFC5;
  border-radius: 50%;
}
.item-circle {
  display: inline-block;
  width: 32px;
  height: 32px;
  border: 1px solid #54BFC5;
  border-radius: 50%;
}
</style>
