<!-- 地址组件 activityComponentType==='4' -->
<template>
  <div class="position">
    <div class="address">
      <div class="left">
      <span :style="`font-size: ${config.titleFont||'18'}px;color: ${config.titleColor||'#353535'}`"
            class="title">{{ config.title }}</span>
        <div :style="`font-size: ${config.addressFont||'14'}px;color: ${config.addressColor||'#666666'}`"
             class="site"
             @click="openLocation(config)">
          <van-image v-if="config.addressIcon" class="site-icon" fit="cover" :src="config.addressIcon"/>
          <van-icon v-else :color="config.addressColor||'#666666'" name="location"/>
          <span :style="`margin-left:${config.addressIcon?'20':0}px`">{{ config.address }}</span>
        </div>
      </div>
      <div v-if="config.phoneNumbersArray"
           class="right"
           @click="onShowPhone(config.phoneNumbersArray)">
        <van-image v-if="config.phoneIcon" class="right-icon" fit="cover" :src="config.phoneIcon"/>
        <van-icon v-else class="icon" name="phone"/>
        <span class="right-text"
              :style="`font-size: ${config.addressFont||'14'}px;color: ${config.subTitleColor||'#666666'}`">{{ config.phoneCopy || '就医电话' }}</span>
      </div>
    </div>

    <!-- 弹窗-电话 -->
    <van-popup
      v-model="showPopPhone"
      close-on-popstate
      closeable
      position="bottom"
      round
      safe-area-inset-bottom
      z-index="999"
    >
      <div class="pop">
        <div class="pop-top">请选择联系方式</div>
        <div
          v-for="(item, index) in arrPhone"
          class="pop-item z-border-top"
          @click="onCallPhone(item)"
        >
          {{ item }}
        </div>
      </div>
    </van-popup>

    <!-- 弹窗-导航 -->
    <van-popup
      v-model="showPopGuide"
      close-on-popstate
      closeable
      position="bottom"
      round
      safe-area-inset-bottom
      z-index="999"
    >
      <div class="pop">
        <div class="pop-top">请选择导航方式</div>
        <div
          v-for="(item, index) in arrGuide"
          class="pop-item z-border-top"
          @click="onChooseGuide(item.type)"
        >
          {{ item.name }}
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>

import api from '@/utils/api'

export default {
  props: {
    // 配置信息
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      dataGuide: {},
      arrPhone: [],
      arrGuide: [
        {
          'name': '百度地图',
          'type': 'bd'
        },
        {
          'name': '高德地图',
          'type': 'gd'
        }
      ],
      showPopPhone: false,
      showPopGuide: false
    }
  },
  methods: {
    // 拨打电话
    onShowPhone(e){
      let list = e || []
      if (list.length == 1) {
        api.callPhone(list[0])
      } else {
        this.showPopPhone = true
        this.arrPhone = list
      }
    },

    // 拨打电话
    onCallPhone(item) {
      api.callPhone(item)
    },

    openLocation(item){
      this.showPopGuide = true
      this.dataGuide = item
    },

    // 导航
    onChooseGuide(signMap) {
      let self = this.dataGuide
      if (self.title && self.title != '') {
        // 位置address 经纬度lng lat
        let lng = self.longitude
        let lat = self.latitude
        if (signMap == 'gd') {
          // 高德地图
          if (this.customBrowserVersion().android) {
            window.location.href = 'androidamap://viewMap?sourceApplication=appname&poiname=' + self.title +
              '&lat=' + lat + '&lon=' + lng + '&dev=0'
            // 判断是否跳转
            setTimeout(function() {
              let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||
                window.document.webkitHidden
              if (typeof hidden == 'undefined' || hidden == false) {
                // 调用高德地图
                window.location.href = 'https://uri.amap.com/marker?position=' + lng + ',' + lat +
                  '&name=' + self.title
              }
            }, 2000)
          } else if (this.customBrowserVersion().ios) {
            window.location.href = 'iosamap://viewMap?sourceApplication=appname&poiname=' + self.title +
              '&lat=' + lat + '&lon=' + lng + '&dev=0'
            // 判断是否跳转
            setTimeout(function() {
              let hidden = window.document.hidden || window.document.mozHidden || window.document.msHidden ||
                window.document.webkitHidden
              if (typeof hidden == 'undefined' || hidden == false) {
                // 调用高德地图
                window.location.href = 'https://uri.amap.com/marker?position=' + lng + ',' + lat +
                  '&name=' + self.title
              }
            }, 2000)
          }
        } else if (signMap == 'bd') {
          // 百度地图
          if (this.customBrowserVersion().android) {
            // 安卓操作系统
            let d = new Date()
            let t0 = d.getTime()
            window.location.href = 'androidamap://viewMap?sourceApplication=appname&poiname=' + self.title +
              '&lat=' + lat + '&lon=' + lng + '&dev=0'
            // 由于打开需要1～2秒，利用这个时间差来处理－－打开app后，返回h5页面会出现页面变成app下载页面，影响用户体验
            let delay = setInterval(function() {
              let d = new Date()
              let t1 = d.getTime()
              if (t1 - t0 < 3000 && t1 - t0 > 2000) {
                window.location.href = 'http://api.map.baidu.com/marker?location=' + lat + ',' +
                  lng + '&title=' + self.title +
                  '&content=&output=html&src=webapp.baidu.openAPIdemo'
              }
              if (t1 - t0 >= 3000) {
                clearInterval(delay)
              }
            }, 1000)
          }
          if (this.customBrowserVersion().ios) {
            // ios操作系统
            let d = new Date()
            let t0 = d.getTime()
            window.location.href = 'iosamap://viewMap?sourceApplication=appname&poiname=' + self.title +
              '&lat=' + lat + '&lon=' + lng + '&dev=0'
            // 由于打开需要1～2秒，利用这个时间差来处理－－打开app后，返回h5页面会出现页面变成app下载页面，影响用户体验
            let delay = setInterval(function() {
              let d = new Date()
              let t1 = d.getTime()
              if (t1 - t0 < 3000 && t1 - t0 > 2000) {
                window.location.href = 'http://api.map.baidu.com/marker?location=' + lat + ',' +
                  lng + '&title=' + self.title +
                  '&content=&output=html&src=webapp.baidu.openAPIdemo'
              }
              if (t1 - t0 >= 3000) {
                clearInterval(delay)
              }
            }, 1000)
          }
        }
      } else {
        api.toast('暂不知道该位置')
      }
      this.showPopGuide = false
    },

    // 区分设备
    customBrowserVersion() {
      let u = navigator.userAgent
      return {
        trident: u.indexOf('Trident') > -1, // IE内核
        presto: u.indexOf('Presto') > -1, // opera内核
        webKit: u.indexOf('AppleWebKit') > -1, // 苹果、谷歌内核
        gecko: u.indexOf('Gecko') > -1 && u.indexOf('KHTML') == -1, // 火狐内核
        mobile: !!u.match(/AppleWebKit.*Mobile.*/), // 是否为移动终端
        ios: !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/), // ios终端
        android: u.indexOf('Android') > -1 || u.indexOf('Adr') > -1, // android终端
        iPhone: u.indexOf('iPhone') > -1, // 是否为iPhone或者QQHD浏览器
        iPad: u.indexOf('iPad') > -1, // 是否iPad
        webApp: u.indexOf('Safari') == -1, // 是否web应用程序，没有头部与底部
        weixin: u.indexOf('MicroMessenger') > -1, // 是否微信 （2015-01-22新增）
        qq: u.match(/\sQQ/i) == ' qq', // 是否QQ
        isBaidu: u.indexOf('baiduboxapp') !== -1, // 是否为百度浏览器
        isqqBrowser: u.indexOf('mqqbrowser') !== -1, // 是否为qq浏览器
        isWxBrowser: u.indexOf('micromessenger') !== -1, // 是否为微信浏览器
        isUc: u.indexOf('ucbrowser') !== -1 // 是否为uc浏览器
      }
    }

  }
}
</script>

<style lang="scss" scoped>
.position {
  margin: 30px 0;
}

.address {
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 90px;
  padding: 20px 15px;
  font-size: 36px;
  background: #ffffff;
  border-radius: 16px;
  margin: 30px 30px;

  .title {
    font-weight: bold;
  }

  .site {
    font-size: 28px;
    color: #666666;
    margin-top: 16px;
    display: flex;
    align-items: baseline;
    position: relative;
  }

  .site-icon {
    width: 30px;
    height: 30px;
    flex: none;
    position: absolute;
    left: 3px;
    top: 5px;
  }

  .right {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex: none;
    margin-left: 15px;
  }

  .right-text {
    margin-top: 16px;
    font-size: 28px;
    color: #666666;
  }

  .right-icon {
    height: 50px;
    width: 50px;
  }

  .right .icon {
    width: 40px;
    height: 40px;
    font-size: 46px;
    color: #333333;
  }
}

/* 弹窗-选择 */
.pop {
  width: 100%;
  min-height: 450px;
  max-height: 900px;
  background: #ffffff;
  overflow: auto !important;
  padding: 120px 0 30px 0;

  .pop-top {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 36px;
    font-weight: bold;
    height: 120px;
    position: absolute;
    top: 0;
    width: 100%;
    background: #ffffff;
    z-index: 1;
    color: #353535;
    padding-left: 15px;
  }

  .pop-item {
    min-height: 150px;
    background: #ffffff;
    color: #353535;
    font-size: 36px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 30px;
  }

  .pop-item_ban {
    background: #f8f8f8 !important;
  }

  .pop-item_select {
    color: #337dd3;
  }
}

</style>
