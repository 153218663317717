/**
 * 基本操作接口
 * toast 显示提示框
 * showLoading 显示加载框
 * hideLoading 隐藏加载框
 * jumpPage 跳转到下一个页面
 * redirectPage 重定向到下一个页面
 * jumpTo 跳转到下一个页面（传参出现在URL中）
 * redirectTo 重定向到下一个页面（传参出现在URL中）
 * backPage 返回上一页
 * getParam 获取页面传参
 * setPageTitle 设置页面的标题
 * getUrlParam 获取链接中参数
 * onPay 微信浏览器调起支付
 * showDialog 显示弹窗
 * showPreviewImg 显示预览图片
 * getStorage 获得缓存数据
 * setStorage 设置缓存数据
 * setSessionStorage 设置会话缓存数据
 * getSessionStorage 获取会话缓存数据
 * removeStorage 清除某个缓存数据
 * clearStorage 清空缓存数据
 * scrollToTop 滚动到顶部
 * scrollToBottom 滚动到底部
 * jumpLink 跳转链接
 * callPhone 拨打电话
 * getAppConfig 获取整个配置文件详细信息
 */

import Vue from 'vue'
import { ImagePreview } from 'vant'
import $config from '@/utils/config'
import { isJSON } from '@/utils/utils'
import qs from 'qs'

const { VUE_APP_MODE } = process.env

export default {

  // /**
  //    * 获取链接中参数
  //    * @param {object} param
  //    * @return null
  //    */
  // getUrlParam(url) {
  //   if (url.indexOf('?') > -1) {
  //     const paramStr = url.split('?')[1]
  //     const paramArr = paramStr.split('&')
  //     const returnObj = {}
  //     for (let i = 0; i < paramArr.length; i++) {
  //       const paramItem = paramArr[i].split('=')
  //       returnObj[paramItem[0]] = paramItem[1]
  //     }
  //     return returnObj
  //   }
  //   return {}
  // },

  /* 获取url参数 */
  getUrlParam({ url, key = '' }) {
    let reg = new RegExp(`([&?])${key}=([^&#]*)`)
    if(!url) {
      url = window.location.search || window.location.hash
    }
    return (url.match(reg) || [])[2]
  },

  /**
     * Dialog 弹出窗
     * @param {String} param 配置文件
     * @param {String} param.title 标题
     * @param {String} param.message 弹窗内容
     * @param {String} param.success 点确定回调
     * @param {String} param.cancel 取消回调
     * @param {String} param.getContainer 指定挂载的节点
     * @return null
     */
  showDialog(param) {
    if (param) {
      Vue.instance.$dialog.alert({
        title: param.title || '提示',
        message: param.message,
        showConfirmButton: param.showConfirmButton || true,
        showCancelButton: param.showCancelButton || false,
        confirmButtonText: param.confirmButtonText || '确认',
        cancelButtonText: param.cancelButtonText || '取消',
        confirmButtonColor: param.confirmButtonColor || '#03CAD8',
        theme: param.theme || '',
        messageAlign: param.messageAlign || 'center',
        getContainer: param.getContainer || ''
      }).then(() => {
        if (param.success) {
          param.success()
        }
      }).catch(() => {
        if (param.cancel) {
          param.cancel()
        }
      })
    }
  },

  /**
     * 显示预览图片
     * @param {object} param 具体配置项，参考有赞
     * @return null
     */
  showPreviewImg(param) {
    ImagePreview(param)
  },

  /**
     * 显示提示框
     * @param {String} msg 需要提示的文字
     * @param {String} position 文字位置
     * @return null
     */
  toast(msg, position = 'center') {
    Vue.instance.$toast({
      message: msg,
      position
    })
  },

  /**
     * 显示加载框，需手动调用hideLoading进行隐藏
     * @param {String} msg 需要显示的文字
     * @return null
     */
  showLoading(msg = '加载中') {
    this.isLoading = Vue.instance.$toast.loading({
      duration: 0,
      forbidClick: true,
      message: msg
    })
  },

  /**
     * 隐藏加载框
     * @return null
     */
  hideLoading() {
    if (this.isLoading) {
      this.isLoading.clear()
    }
  },

  /**
     * 获取传参
     * @return {Object} data 参数
     */
  getParam() {
    const { name } = Vue.instance.$route
    let param = Vue.instance.$route.params
    const { query } = Vue.instance.$route
    if (Object.keys(param).length <= 0) {
      param = JSON.parse(sessionStorage.getItem(name) || '{}')
    }
    param = {
      ...param,
      ...query
    }
    for (const k in param) {
      if(k != 'richText') {
        param[k] = decodeURIComponent(param[k])
        if (isJSON(param[k])) {
          param[k] = JSON.parse(param[k])
        }
      }
    }
    return param || {}
  },

  /**
     * 设置拼接后的参数
     * @param {String} dest 路径
     * @param {String} data 参数
     * @return {String}
     */
  setPageParam(dest, data) {
    for (const k in data) {
      if (typeof data[k] === 'object') {
        data[k] = JSON.stringify(data[k])
      }
      data[k] = encodeURIComponent(data[k])
    }
    sessionStorage.setItem(dest, JSON.stringify(data || '{}'))
    return data
  },

  /**
     * query模式获取拼接后的参数
     * @param {String} dest 路径
     * @param {String} data 参数
     * @return {String}
     */
  getPageQuery(dest, data) {
    for (const k in data) {
      data[k] = encodeURIComponent(data[k] === 0 || !!data[k] ? data[k] : '')
    }
    return data
  },

  /**
     * 跳转到下一个页面
     * @param {String} dest 跳转页面的名称
     * @param {Object} query 跳转页面的传参
     * @return null
     */
  jumpPage(dest, query = {}) {
    if (!dest) {
      return
    }
    const option = {
      name: dest,
      query
    }

    // 参数带到url的页面
    // const specialPages = ['home', 'genetictest-test-self', 'course-set', 'course-series', 'course-detail', 'course-article']

    // // 参数带到url的页面
    // if(specialPages.includes(option.name)) {
    //   option.query = {
    //     ...param
    //   }
    //   Vue.instance.$router.push(option)
    //   return
    // }

    // if (param) {
    //   if (Object.prototype.toString.call(param) === '[object Object]') {
    //     option.params = this.setPageParam(dest, param)
    //   } else {
    //     this.log('传参格式出错！')
    //   }
    // }

    Vue.instance.$router.push(option)
    // this.scrollToTop()
  },

  /**
     * 重定向页面
     * @param {String} dest 跳转页面的名称
     * @param {Object} query 跳转页面的传参
     * @return null
     */
  redirectPage(dest, query = {}) {
    if (!dest) {
      return
    }
    const option = {
      name: dest,
      query
    }
    // if (param) {
    //   if (Object.prototype.toString.call(param) === '[object Object]') {
    //     option.params = this.setPageParam(dest, param)
    //   } else {
    //     this.log('传参格式出错！')
    //   }
    // }
    Vue.instance.$router.replace(option)
  },

  /**
     * 跳转到下一个页面（参数跟在url后面）
     * @param {String} dest 跳转页面的地址
     * @param {Object} param 跳转页面的传参
     * @return null
     */
  jumpTo(dest, param = {}) {
    if (!dest) {
      return
    }
    const option = {
      path: dest
    }
    if (param) {
      if (Object.prototype.toString.call(param) === '[object Object]') {
        option.query = this.getPageQuery(dest, param)
      } else {
        this.log('传参格式出错！')
      }
    }
    Vue.instance.$router.push(option)
  },

  /**
     * 跳转到下一个页面（参数跟在url后面）
     * @param {String} dest 跳转页面的地址
     * @param {Object} param 跳转页面的传参
     * @return null
     */
  redirectTo(dest, param = {}) {
    if (!dest) {
      return
    }
    const option = {
      name: dest
    }
    if (param) {
      if (Object.prototype.toString.call(param) === '[object Object]') {
        option.query = this.getPageQuery(dest, param)
      } else {
        this.log('传参格式出错！')
      }
    }
    Vue.instance.$router.replace(option)
  },

  /**
     * 返回上一页
     * @return null
     */
  backPage() {
    Vue.instance.$router.go(-1)
  },

  /**
     * 设置页面标题
     * @param {Object} title 标题
     * @return null
     */
  setPageTitle(title) {
    if (document) {
      document.title = title
    }
  },

  /**
     * 获取缓存
     * @param {String} key
     * @return null
     */
  getStorage(key) {
    key = `${key}_${VUE_APP_MODE}`
    return localStorage.getItem(key)
  },

  /**
     * 设置缓存
     * @param {String} key
     * @param {Object} data
     * @return null
     */
  setStorage(key, data) {
    key = `${key}_${VUE_APP_MODE}`
    localStorage.setItem(key, data)
  },

  /**
     * 设置会话缓存
     * @param {String} key
     * @param {Object} data
     * @return null
     */
  setSessionStorage(key, data) {
    key = `${key}_${VUE_APP_MODE}`
    sessionStorage.setItem(key, data)
  },

  /**
     * 获取缓存
     * @param {String} key
     * @return null
     */
  getSessionStorage(key) {
    key = `${key}_${VUE_APP_MODE}`
    return sessionStorage.getItem(key)
  },

  /**
     * 删除某项缓存
     * @param {String} key
     * @return null
     */
  removeStorage(key) {
    key = `${key}_${VUE_APP_MODE}`
    localStorage.removeItem(key)
  },

  /**
     * 清空所有缓存
     * @return null
     */
  clearStorage() {
    localStorage.clear()
  },

  /**
     * 微信浏览器调起微信支付
     */
  onBridgeReady(params, success, faile) {
    return new Promise((resolve, reject) => {
      WeixinJSBridge.invoke(
        'getBrandWCPayRequest',
        {
          appId: params.appId, // 公众号名称，由商户传入
          timeStamp: params.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。成签但最新版的支付后台生名使用的timeStamp字段名需大写其中的S字符
          nonceStr: params.nonceStr, // 支付签名随机串，不长于 32 位
          package: params.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
          signType: params.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
          paySign: params.paySign // 支付签名
        },
        (res) => {
          if (res.err_msg == 'get_brand_wcpay_request:ok') {
            // 使用以上方式判断前端返回,微信团队郑重提示：
            // res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
            resolve(res)
          }
          if (res.err_msg == 'get_brand_wcpay_request:cancel') {
            // 支付过程中用户取消
            reject(res)
          }
          if (res.err_msg == 'get_brand_wcpay_request:fail') {
            // 支付失败
            reject(res)
          }
        }
      )
    })
  },

  onPay(params = {}, success, faile) {
    return onBridgeReady(params, success, faile)
  },

  // 滚动到顶部
  scrollToTop() {
    window.scrollTo(0, 0)
  },

  // 滚动到顶部
  scrollToBottom() {
    const t = document.body.clientHeight
    window.scrollTo(0, t)
  },

  /**
     * 跳转
     * @param {object} param linkType: 0内部路由｜1外部链接｜2小程序
     * @return null
     */
  jumpLink(param) {
    let url = param.linkSrc
    if (param.linkType == 0) {
      let str = {}
      const urlArr = url.split('?')
      url = urlArr[0]
      if (urlArr.length > 1) {
        str = qs.parse(urlArr[1])
      }
      console.log(url, str)
      this.jumpPage(url, str)
    }
    if (param.linkType == 1) {
      // window.open(url)//不覆盖当前页面重新打开新的页面
      window.location.href = url
    }
    if (param.linkType == 2) {
      // wxInitTag()
    }
  },

  // 拨打电话
  callPhone(phone) {
    let a = document.createElement('a')
    a.href = `tel:${phone}`
    a.click()
    a.remove()
  },

  /**
   * 获取详细配置
   * @return {Object} data
   */
  getAppConfig() {
    return $config
  },

  // 跳转pdf
  openPdf(url) {
    if (this.getIsIos()){
      window.open(url)
    } else {
      this.jumpPage('pdf-index', {
        pdfSrc: url
      })
    }
  },

  // 判断是否为ios系统：是IOS：true，不是：false。
  getIsIos(){
    let u = navigator.userAgent
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) // ios终端
    return isiOS
  }
}

