<template>
  <div class="wrap">
    <div class="head">
      <div class="head__logo">
        <img
          class="head__img"
          src="@/assets/login-logo.png"
        />
        <p>您好 请登录</p>
      </div>
    </div>
    <div class="form">
      <div class="form__row z-border-bottom">
        <van-icon
          name="user-circle-o"
          class="form__icon"
        />
        <input
          class="form__ipt"
          placeholder="请输入手机号"
          v-model="dataForm.telPhone"
        />
      </div>
      <div class="form__row z-border-bottom">
        <van-icon
          name="shield-o"
          class="form__icon"
        />
        <input
          class="form__ipt"
          placeholder="请输入验证码"
          v-model="dataForm.telCode"
        />
        <div
          class="form__yzm"
          :class="dataForm.telPhone?'z-color--blue':''"
          @click="getCode"
        >
          {{canSend ?'获取验证码':`${codeTime}s后重试`}}
        </div>
      </div>
      <div class="form__bottom">
        <button
          class="form__btn"
          :class="canLogin?'':'form__btn--grey'"
          @click="onLogin"
        >立即登录</button>
        <div class="form__read">
          <van-checkbox
            checked-color="#53B1FE"
            v-model="isAgree"
            @change="onAgreeDeal"
          >
            授权登录代表您已阅读并同意
            <span
              class="z-color--blue"
              @click.stop="clickProtocol('user')"
            >《用户协议》</span>
            及
            <span
              class="z-color--blue"
              @click.stop="clickProtocol('secret')"
              data-name="secret"
            >《隐私协议》</span>
            ，开始咨询代表您已阅读并同意
            <span
              class="z-color--blue"
              @click.stop="clickProtocol('risk')"
            >《互联网诊疗风险告知及知情同意书》</span>
          </van-checkbox>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import api from '@/utils/api'
import { countDown } from '@/mixins/countDown'
// import { loginTIM } from '@/plugin/tencentIM/im-init'
import { loginIM } from '@/plugin/rong-cloud/im-init'
import { getVerificationCode, phoneLogin, doPhoneValiCodeLogin } from '@/api/index'
import { messageSignature } from '@/utils/utils'
import qs from 'qs'

export default {
  mixins: [countDown],

  data() {
    return {
      dataOption: {},
      dataForm: {
        telPhone: '',
        telCode: ''
      },
      isAgree: true
    }
  },

  computed: {

    ...mapGetters([
      'getClinicCode',
      'getOpenId'
    ]),

    canLogin() {
      return this.dataForm.telPhone && this.dataForm.telCode
    }
  },

  created() {
    this.dataOption = api.getParam()
  },

  methods: {

    // 获取验证码
    getCode() {
      if (!this.dataForm.telPhone || !this.canSend) {
        return
      }
      let param = {
        clinicCode: this.getClinicCode,
        phone: this.dataForm.telPhone,
        pwdLostFlag: 1
        // verificationCodeType: 'webLogin'
        // encryptFlag: 1,
      }
      param.sign = messageSignature(param)
      getVerificationCode(param).then(res => {
        this.sendCode()
      }).catch(err => {

      })
    },

    // 是否勾选同意协议
    onAgreeDeal(e) {
      if (!e) {
        this.isAgree = false
      } else {
        this.isAgree = true
      }
    },

    // 立即登录
    onLogin() {
      if (!this.canLogin) {
        return
      }
      if (!this.isAgree) {
        return api.toast('请仔细阅读并同意相关协议')
      }

      let paramNew = {
        clinicCode: this.getClinicCode,
        mobile: this.dataForm.telPhone,
        validateCode: this.dataForm.telCode,
        thirdId: this.getOpenId,
        thirdType: 'wx'
        // encryptFlag: 1
      }
      paramNew.sign = messageSignature(paramNew)
      paramNew.encryptFlag = 1
      doPhoneValiCodeLogin(paramNew).then(resNew => {
        if (resNew.code == 0) {
          this.$store.commit('SET_TOKEN', resNew.data.tokenId)
          let param = {
            clinicCode: this.getClinicCode, // 社区编号
            openId: this.getOpenId, // openId
            phoneNumber: this.dataForm.telPhone,
            verificationCode: this.dataForm.telCode
          }
          phoneLogin(param).then((res) => {
            let data = {
              ...res.data
              // token: res.token || ''
            }
            console.log('登录成功', res)
            this.$store.commit('SET_USER_INFO', data)
            // 登录im
            // loginTIM()
            loginIM()
            // 重定向
            this.redirect()
          }).catch((err) => {

          })
        }
      }).catch((err) => {

      })

      // postLogin(param).then(res => {

      // }).catch(err => {
      //   api.toast(err)
      // })

      // // 保存用户信息
      // this.$store.commit('SET_USER_INFO', {
      //   identifier: 'TEST_C1123',
      //   userSig: 'eJyrVgrxCdZLrSjILEpVsjI0AAMdsGBZapGSlZKRnoEShF*ckp1YUJCZAlRmYmBgamBqZGkKkclMSc0ryUzLBGsIcQ0OiXc2NDQyhunLTAcK*5olZfsWmOValgQHJlo6mma6BacEuJi5VJWWBRqH5ESm**WY*ru6VJoYl9tCNZZk5oLcZG5gaWlqYmZqVgsArDcxgA__',
      //   token: '123',
      //   openId: '222',
      //   userId: '89757'
      // })

      // // 登录im
      // loginTIM()

      // // 重定向
      // this.redirect()
    },

    // 点击查看协议
    clickProtocol(name) {
      api.jumpPage('about', { name })
    },

    // 登录后重定向页面
    redirect() {
      this.$router.replace({
        path: this.dataOption.redirectUrl || '/',
        query: qs.parse(this.dataOption.param || {})
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.wrap {
  ::v-deep {
    .van-radio__icon {
      font-size: 36px;
    }
  }
  background-color: #ffffff;
  flex: 1;

  .head {
    display: flex;
    justify-content: center;
    padding: 60px 40px 40px 40px;

    .head__logo {
      display: flex;
      flex-direction: column;
      align-items: center;
      font-size: 56px;

      .head__img {
        width: 160px;
        height: 160px;
        display: block;
        margin-bottom: 20px;
      }
    }
  }

  .form {
    padding: 60px 40px;

    .form__row {
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
      min-height: 100px;
      padding: 20px 0;
      margin-bottom: 20px;
      font-size: 32px;
    }

    .form__icon {
      flex: none;
      font-size: 48px;
      margin-right: 30px;
      color: #666666;
    }

    .form__ipt {
      flex: auto;
      border: none;
      font-size: 32px;
      height: 100px;
    }

    .form__yzm {
      border-left: 2px solid #c1c1c1;
      display: flex;
      align-items: center;
      height: 48px;
      padding-left: 40px;
      word-break: keep-all;
      color: #666666;
      width: 200px;
    }

    .form__bottom {
      margin-top: 60px;
      padding: 40px 0;
    }

    .form__btn {
      height: 100px;
      width: 100%;
      background: #53b1fe;
      display: flex;
      align-items: center;
      justify-content: center;
      border: none;
      color: #ffffff;
      border-radius: 100px;
      font-size: 36px;
    }

    .form__btn--grey {
      background-color: #f5f5f5 !important;
      color: #ababab;
    }

    .form__read {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 40px;
      font-size: 24px;
      color: #666666;
    }
  }
}
</style>
