export default [
  {
    path: '/chat',
    name: 'chat',
    component: () => import('@/views/chat/index.vue'),
    meta: {
      title: '聊天'
    }
  },
  {
    path: '/chat/list',
    name: 'chat-list',
    component: () => import('@/views/chat/list'),
    meta: {
      title: '消息列表'
    }
  },
  {
    path: '/chat/system',
    name: 'chat-system',
    component: () => import('@/views/chat/system.vue'),
    meta: {
      title: '系统消息'
    }
  }
]
