<template>
  <div class="">
    <van-dialog
      v-model="showPop"
      :title="title"
      :message="message"
      :theme="theme"
      :confirm-button-text="confirmButtonText"
      :cancel-button-text="cancelButtonText"
      :show-confirm-button="showConfirmBtn"
      :show-cancel-button="showCancelBtn"
      :close-on-click-overlay="closeOnClickOverlay"
      @close="onClose"
      @confirm="onConfirm"
    >
      <img
        v-if="showImg"
        :src="imgUrl"
      >
      <slot name="content" />
    </van-dialog>
  </div>
</template>

<script>

export default {
  name: 'ZDialog',

  model: {
    prop: 'showDialog'
  },

  props: {
    title: {
      type: String,
      default: '提示'
    },
    message: {
      type: String,
      default: ''
    },
    confirmButtonText: {
      type: String,
      default: '确定'
    },
    cancelButtonText: {
      type: String,
      default: '取消'
    },
    imgUrl: {
      type: String,
      default: ''
    },
    theme: {
      type: String,
      // round-button
      default: ''
    },
    showImg: {
      type: Boolean,
      default: false
    },
    showDialog: {
      type: Boolean,
      default: false
    },
    showConfirmBtn: {
      type: Boolean,
      default: true
    },
    showCancelBtn: {
      type: Boolean,
      default: true
    },
    closeOnClickOverlay: {
      type: Boolean,
      default: false
    },
    close: {
      type: Function,
      default: null
    },
    confirm: {
      type: Function,
      default: null
    }
  },

  data() {
    return {
      showPop: false
    }
  },

  watch: {
    showDialog() {
      this.showPop = this.showDialog
    }
  },

  methods: {
    onClose() {
      if (this.close) {
        this.close()
        // this.$emit('close')
      }
      // this.showPop = false
    },

    onConfirm() {
      if (this.confirm) {
        this.confirm()
      }
    }

  }
}

</script>

<style lang="scss" scoped>
</style>
