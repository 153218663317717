<template>
  <div class="doc-card-wrap">
    <div class="box"
         :class="item.visitTime?'':'flex-center'"
         v-for="(item,index) in arrDoc"
         :key="index"
         @click="onJumpHome(item)"
    >
      <van-image
        :src="item.doctorImage"
        class="box-img"
        round
        fit="cover"
      />
      <div class="box-info">
        <div class="box-info_title">我是
          <span class="font-blue">{{ item.doctorName }}医生</span>
          <span v-if="item.doctorLabel">，{{ item.doctorLabel }}</span>
        </div>
        <template v-if="item.visitTime">
          <div class="box-info_subtitle">
            <span class="font800">出诊时间：</span>
            <span class="font-grey">{{ item.visitTime||'请联系诊室' }}</span>
          </div>
        </template>
        <div class="box-info_btn" :style="`margin-top:${item.doctorLabel?'19':'25'}px`">向我咨询</div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api'

export default {
  name: 'ZDocCard',
  props: {
    arrDoc: {
      type: Array,
      default: []
    }
  },

  data() {
    return {
    }
  },

  methods: {
    onJumpHome(item){
      api.jumpPage('doctor-home', {
        doctorCode: item.doctorCode
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.box {
  width: 690px;
  min-height: 250px;
  background: linear-gradient(360deg, #FFFFFF 0%, #E6F4FE 76%, #D7EEFE 100%);
  border-radius: 16px 16px 16px 16px;
  opacity: 1;
  margin: 20px auto;
  padding: 30px 20px;
  display: flex;
  align-items: flex-start;

  .box-img {
    flex: none;
    width: 178px;
    height: 178px;
    border-radius: 50%;
    border: 4px solid #FFFFFF;
    margin-right: 20px;
  }

  .box-info {
    .box-info_title {
      font-size: 36px;
      font-weight: bold;
      color: #353535;
      line-height: 48px;
    }

    .box-info_subtitle {
      font-size: 30px;
      line-height: 40px;
      margin-top: 12px;
      //&::before {
      //  content: '';
      //  display: inline-block;
      //  width: 451px;
      //  height: 0.5px;
      //  background: #BED6F1;
      //  margin: 15px 0;
      //}
    }
    .box-info_btn {
      width: 162px;
      height: 60px;
      background: #4085D5;
      border: 1px solid #337DD3;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #FFFFFF;
      border-radius: 50px;
      margin-top: 19px;
      margin-left: 290px;
    }
  }
}

.font-blue {
  color: #337DD3;
}

.font-grey {
  color: #666666;
}

.flex-center {
  align-items: center;
}
</style>
