import ZDialog from '@/components/ZDialog'
import ZEmpty from '@/components/ZEmpty'
import ZFooterBtn from '@/components/ZFooterBtn'
import ZUploaderImg from '@/components/ZUploaderImg'
import ZUploaderImgs from '@/components/ZUploaderImgs'
import ZUploaderAsync from './ZUploaderAsync'
import ZDocCard from './ZDocCard'
import ZDocCate from './ZDocCate'
import ZAudio from './ZAudio'
import ZVideo from './ZVideo'
import ZHeader from './ZHeader'
import ZAppraise from './ZAppraise'
import ZCoupon from './ZCoupon'
import ZPop from './ZPop'
import ZVipCard from './ZVipCard'
import ZCouponPop from './ZCouponPop'
import ZCouponItem from './ZCouponItem'

export default function(Vue) {
  Vue.component('ZDialog', ZDialog)
  Vue.component('ZEmpty', ZEmpty)
  Vue.component('ZFooterBtn', ZFooterBtn)
  Vue.component('ZUploaderImg', ZUploaderImg)
  Vue.component('ZUploaderImgs', ZUploaderImgs)
  Vue.component('ZUploaderAsync', ZUploaderAsync)
  Vue.component('ZDocCard', ZDocCard)
  Vue.component('ZDocCate', ZDocCate)
  Vue.component('ZAudio', ZAudio)
  Vue.component('ZVideo', ZVideo)
  Vue.component('ZHeader', ZHeader)
  Vue.component('ZAppraise', ZAppraise)
  Vue.component('ZCoupon', ZCoupon)
  Vue.component('ZPop', ZPop)
  Vue.component('ZVipCard', ZVipCard)
  Vue.component('ZCouponPop', ZCouponPop)
  Vue.component('ZCouponItem', ZCouponItem)
}
