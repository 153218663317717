import api from '@/api/api'

// 获取聊天列表
export function getConversationList(data) {
    return api.get({
        url: '/h5/common/imMessage/getConversationList',
        data
    })
}

/**
 * 获取im聊天记录
 */
export function getMessageRecord(data) {
    return api.get({
      url: '/h5/common/imMessage/getMessageRecord',
      data
    })
  }

/**
 * 获取im最后一条订单记录
 */
export function getPatientLastTreat(data) {
    return api.get({
        url: '/h5/common/imMessage/getPatientLastTreatInfo',
        data
    })
}

// 获取未读消息数
export function getUnReadMsgCount(data) {
  return api.get({
    url: '/h5/home/page/getUnReadMsgCount',
    data
  })
}

// 获取病情描述信息
export function getPatientTreatInfo(data) {
  return api.get({
    url: '/h5/common/imMessage/getPatientTreatInfo',
    data
  })
}

// 获取问诊小结信息
export function getTreatSummaryInfo(data) {
  return api.get({
    url: '/h5/common/imMessage/getTreatSummaryInfo',
    data
  })
}
