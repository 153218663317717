
export default {
  // 获取社区编号
  getClinicCode: (state) => state.base.clinicCode,
  // 免登凭证
  getCertSign: (state) => state.user.certSign,
  // 获取openId
  getOpenId: (state) => state.user.openId,
  // 获取登录token
  getToken: (state) => state.user.token,
  // 获取用户im信息
  getUserIm: (state) => state.user.userIm,
  // 获取用户信息
  getUserInfo: (state) => state.user.userInfo
}
