import api from '@/utils/api'

export default {
  state: {
    // // 专题页编号
    // clinicTopinCode: api.getStorage('CLINIC_TOPIN_CODE'),
    // 社区编号
    clinicCode: api.getStorage('CLINIC_CODE') || null

  },
  mutations: {
    // // 设置专题页编号
    // SET_CLINIC_TOPIN_CODE(state, data) {
    //   state.clinicTopinCode = data || null
    //   api.setStorage('CLINIC_TOPIN_CODE', data)
    // },

    // 设置社区编号
    SET_CLINIC_CODE(state, data) {
      state.clinicCode = data || null
      api.setStorage('CLINIC_CODE', data)
    }
  }
}
