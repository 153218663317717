import Vue from 'vue'
import VueRouter from 'vue-router'
import home from '@/views/home/index.vue'
import login from '@/views/login/index.vue'
import article from './modules/article'
import dept from './modules/dept'
import doctor from './modules/doctor'
import Home from './modules/home'
import about from './modules/about'
import chat from './modules/chat'
import my from './modules/my'
import order from './modules/order'
import patient from './modules/patient'
import pat from './modules/pat'
import kids from './modules/kids'
import genetictest from './modules/genetictest'
import course from './modules/course'
import vip from './modules/vip'
import manage from './modules/manage'
import offline from './modules/offline'

const originalPush = VueRouter.prototype.push
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.push = function push(location, resolve, reject) {
  if (resolve || reject) {
    return originalPush.call(this, location, resolve, reject)
  }
  return originalPush.call(this, location).catch(e => {})
}
VueRouter.prototype.replace = function push(location, resolve, reject) {
  if (resolve || reject) {
    return originalReplace.call(this, location, resolve, reject)
  }
  return originalReplace.call(this, location).catch(e => {})
}

Vue.use(VueRouter)

const routes = [
  ...article,
  ...dept,
  ...doctor,
  ...Home,
  ...about,
  ...chat,
  ...my,
  ...order,
  ...patient,
  ...pat,
  ...kids,
  ...genetictest,
  ...course,
  ...vip,
  ...manage,
  ...offline,
  {
    path: '/',
    name: 'home',
    component: home,
    meta: {
      // 无需登录
      whitelist: true
    }
  },
  {
    path: '/video',
    name: 'video',
    component: () => import('@/views/video/index'),
    meta: {
      title: '视频'
    }
  },
  {
    path: '/login',
    name: 'login',
    component: login,
    meta: {
      title: '登录',
      // 无需登录
      whitelist: true
    }
  },
  {
    path: '*',
    name: '404',
    component: () => import('@/views/404/index.vue'),
    meta: {
      title: '找不到该页面哦',
      // 无需登录
      whitelist: true
    }
  }
]

const router = new VueRouter({
  routes
})

export default router
