<template>
  <div class="empty-wrap">
    <img
      :src="src"
      class="empty-img"
    >
    <div class="empty-title" v-if="showTitle">
      {{ title }}
    </div>
    <slot name="content"></slot>
  </div>
</template>

<script>

export default {
  name: 'ZEmpty',
  props: {
    title: {
      type: String,
      default: '暂无数据'
    },
    src: {
      type: String,
      default: require('@/assets/images/empty_default.png')
    },
    showTitle: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
    }
  },

  methods: {

  }
}
</script>

<style lang="scss" scoped>
.empty-wrap {
  height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 30px 0 150px 0;
  min-height: 500px;
}

.empty-title {
  font-size: 32px;
  color: #b7b7b7;
  padding: 0 40px;
  text-align: center;
}

.empty-img {
  width: 400px;
  height: 400px;
  margin-bottom: 50px;
}
</style>
