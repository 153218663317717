<template>
  <div>
    <van-uploader
      :after-read="afterRead"
      :capture="capture"
    >
      <slot></slot>
    </van-uploader>
  </div>
</template>

<script>
import api from '@/utils/api'
export default {
  name: 'ZUploaderAsync',
  props: {
    capture: {
      type: String,
      value: ''
    }
  },
  methods: {
    // 上传图片读取完成后的回调函数
    afterRead(file) {
      let index = file.file.type.indexOf('/')
      let type = file.file.type.substr(index + 1, file.file.type.length)
      let param = {
        base64Str: file.content,
        fileType: type,
        saveType: 'TREAT_IMAGES',
        file: file
      }
      this.$emit('afterRead', param)
    }
  }
}
</script>
