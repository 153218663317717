<template>
  <div class="footer">
    <div
      class="footer-site"
      :class="footerTip?'footer-site_height1':'footer-site_height2'"
    />
    <div class="footer-m">
      <slot name="content" />
      <div v-if="showContent">
        <div class="footer-m_btn-two">
          <div
            v-if="btnLeftText"
            class="btn btn2 btn-left"
            :style="{ background: btnLeftBg, color: btnLeftColor }"
            @click="onToBack"
          >
            {{ btnLeftText }}
          </div>
          <div
            v-if="btnRightText"
            class="btn btn2 btn-right"
            :style="{ background: btnRightBg, color: btnRightColor }"
            @click="onToNext"
          >
            {{ btnRightText }}
          </div>
        </div>
        <div
          v-if="btnOneText"
          class="footer-m_btn-one"
          :style="{ background: btnOneBg }"
          @click="onSubmit"
        >
          {{ btnOneText }}
        </div>
        <div
          v-if="footerTip"
          class="footer-m_tip"
          @click="onShowTip"
        >
          {{ footerTip }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ZFooterBtn',
  props: {
    btnOneBg: {
      type: String,
      default: '#4085D5'
    },
    btnLeftBg: {
      type: String,
      default: '#FFFFFF'
    },
    btnLeftColor: {
      type: String,
      default: '#666666'
    },
    btnRightBg: {
      type: String,
      default: '#4085D5'
    },
    btnRightColor: {
      type: String,
      default: '#FFFFFF'
    },
    showContent: {
      type: Boolean,
      default: true
    },
    btnLeftText: {
      type: String,
      default: ''
    },
    btnRightText: {
      type: String,
      default: ''
    },
    btnOneText: {
      type: String,
      default: ''
    },
    footerTip: {
      type: String,
      default: ''
    },
    submit: {
      type: Function,
      default: null
    },
    back: {
      type: Function,
      default: null
    },
    next: {
      type: Function,
      default: null
    },
    showtip: {
      type: Function,
      default: null
    }
  },

  data() {
    return {}
  },

  methods: {
    onSubmit() {
      if (this.submit) {
        this.submit()
      }
    },
    onToBack() {
      if (this.back) {
        this.back()
      }
    },
    onToNext() {
      if (this.next) {
        this.next()
      }
    },
    onShowTip() {
      if (this.showtip) {
        this.showtip()
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* footer */
.footer {
  //height: 30%;
  height: 8%;
}

.footer-site_height1 {
  height: 200px;
}

.footer-site_height2 {
  height: 150px;
}

.footer-site {
  width: 100%;
  box-sizing: content-box;
  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}

.footer-m {
  width: 100%;
  text-align: center;
  overflow-x: hidden;
  background-color: #ffffff;
  border-top: 1px solid #f1f1f1;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  box-sizing: content-box;
  padding-bottom: constant(safe-area-inset-bottom); /*兼容 IOS<11.2*/
  padding-bottom: env(safe-area-inset-bottom); /*兼容 IOS>11.2*/
}

.footer-m::after {
  position: absolute;
  top: 0;
  left: 0;
  content: '';
  width: 100%;
  height: 1px;
  border-top: 1px solid #f1f1f1;
  transform: scaleY(0.5);
}

.footer-m_btn-two {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.btn {
  width: 650px;
  height: 90px;
  background: #4085D5;
  opacity: 1;
  border-radius: 46px;
  font-size: 36px;
  font-weight: bold;
  line-height: 90px;
  color: #fefefe;
  text-align: center;
  margin: 25px 30px;
}

.btn2 {
  margin: 25px auto;
  width: 320px;
}

.btn-left {
  //background-color: #f1f1f1;
  //color: #666666;
  border: 1px solid #dedede;
}

.footer-m_btn-one {
  width: 650px;
  height: 90px;
  background: #4085D5;
  opacity: 1;
  border-radius: 46px;
  font-size: 36px;
  font-weight: bold;
  line-height: 90px;
  color: #ffffff;
  text-align: center;
  margin: 25px auto;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 0 15px;
}

.footer-m_tip {
  font-size: 24px;
  line-height: 34px;
  color: #666666;
  padding-bottom: 16px;
}
</style>
