export default [
  {
    path: '/kids/growth-record',
    name: 'kids-growth-record',
    component: () => import('@/views/kids/growth-record/index'),
    meta: {
      title: '儿童档案'
    }
  },
  {
    path: '/kids/child-add',
    name: 'kids-child-add',
    component: () => import('@/views/kids/child-add/index'),
    meta: {
      title: '编辑信息'
    }
  },
  {
    path: '/kids/add-growth-record',
    name: 'kids-add-growth-record',
    component: () => import('@/views/kids/add-growth-record/index'),
    meta: {
      title: '添加记录'
    }
  },
  {
    path: '/kids/record-detail',
    name: 'kids-record-detail',
    component: () => import('@/views/kids/record-detail/index'),
    meta: {
      title: '记录详情'
    }
  },
  {
    path: '/kids/report/list',
    name: 'kids-report-list',
    component: () => import('@/views/kids/report/list'),
    meta: {
      title: '足测报告'
    }
  },
  {
    path: '/kids/report/user-add',
    name: 'kids-report-user-add',
    component: () => import('@/views/kids/report/user-add'),
    meta: {
      title: '编辑信息'
    }
  },
  {
    path: '/kids/report/start',
    name: 'kids-report-start',
    component: () => import('@/views/kids/report/start'),
    meta: {
      title: '开始测量'
    }
  },
  {
    path: '/kids/report/user-list',
    name: 'kids-report-user-list',
    component: () => import('@/views/kids/report/user-list'),
    meta: {
      title: '开始测量'
    }
  }

]
