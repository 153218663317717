export default [
  {
    path: '/home/richtext',
    name: 'home-richtext',
    component: () => import('@/views/home/richtext.vue'),
    meta: {
      title: '详情'
    }
  }
]
