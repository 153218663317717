import store from '@/store'
import router from '@/router'
import { Toast } from 'vant'
import qs from 'qs'
import { loginIM } from '@/plugin/rong-cloud/im-init'
import auth from '@/utils/auth'
import { postLoginInfoByCert, doThirdPartyAuthorLogin } from '@/api/index'

// 页面权限
export default async function(route, next) {
  // 社区编号
  const clinicCode = store.getters.getClinicCode
  // 免登凭证
  const certSign = route.query.certSign || null
  // token
  const token = store.getters.getToken
  // 需要去除的页面参数
  const filterParams = ['openID', 'unionId', 'appId']

  if(!clinicCode) return Toast('请提供社区编号')

  // 当url存在免登凭证且跟本地缓存的免登凭证不一致的话
  // certSign && (certSign != store.getters.getCertSign)

  // 存在免登凭证的情况
  if(certSign && (certSign != store.getters.getCertSign)) {
    // 保存免登凭证到本地
    store.commit('SET_CERTSIGN', certSign)
    try {
      // 获取免登前准备信息
      const res = await postLoginInfoByCert({ certSign })
      if (res.data.userInfo) {
        let params = {
          mobile: res.data.userInfo.phoneNumber,
          appCode: 'HC_BL_WX'
        }
        // 用户信息
        const userInfo = await doThirdPartyAuthorLogin(params)
        // 是否返回token
        if(userInfo.data?.tokenId) {
          // 保存用户信息
          store.commit('SET_TOKEN', userInfo.data.tokenId)
          store.commit('SET_USER_INFO', { ...res.data })
          loginIM()
          next()
        } else {
          // 没有返回token就抛出异常走catch逻辑
          // eslint-disable-next-line no-throw-literal
          throw false
        }
      }
    } catch(err) {
        // 登录失败就强转登录页
        // 当前页面参数
        const query = { ...route.query }
        delete query['certSign']
        router.replace({
          path: '/login',
          query: {
            redirectUrl: route.path,
            param: qs.stringify(query)
          }
        })
    }
  } else if(token || route.meta.whitelist) {
     // 存在token或白名单页面情况下

    //  let query = { ...route.query }
    //  let find = false
    //  filterParams.forEach((item) => {
    //   if(item in route.query) {
    //     find = true
    //   }
    //   delete query[item]
    // })

    // if(find) {
    //   console.log(33333333333333333)
    //   router.replace({
    //     path: route.path,
    //     query: query
    //   })
    // } else {
    //   next()
    // }

    if(token) {

    }
    next()
  } else {
    // 需要去登录了
    try {
      const data = await auth(route) || {}
      loginIM()

      if(data.path) {
        // 有回调地址的话直接强制重定向
        router.replace({
          path: data.path,
          query: data.query
        })
        location.reload()
      } else {
        next()
      }
    } catch(err) {
      // 当前页面参数
      const query = { ...route.query }
      // 过滤完拿到最终转向url
      filterParams.forEach((item) => {
        delete query[item]
      })

      router.replace({
        path: '/login',
        query: {
          redirectUrl: route.path,
          param: qs.stringify(query)
        }
      })
    }
  }
}
