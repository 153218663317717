import api from '@/utils/api'

export default {
  state: {
    // 换取token凭证，由url带入
    certSign: api.getSessionStorage('CERTSIGN') || null,
    // openId
    openId: api.getStorage('OPENID') || null,
    // token
    token: api.getStorage('TOKEN') || null,
    // 用户信息
    userInfo: JSON.parse(api.getStorage('USER_INFO')) || {},
    // 用户im信息
    userIm: JSON.parse(api.getStorage('USER_IM')) || {}
  },
  mutations: {
    // 设置certSign
    SET_CERTSIGN(state, data) {
      state.certSign = data || null
      api.setSessionStorage('CERTSIGN', data || null)
    },
    // 设置openId
    SET_OPENID(state, data) {
      state.openId = data || null
      api.setStorage('OPENID', data || null)
    },
    // 设置token
    SET_TOKEN(state, token) {
      state.token = token || null
      api.setStorage('TOKEN', token || null)
    },

    // 设置token、im、用户信息
    SET_USER_INFO(state, data) {
      let userIm = {
        IM_ID: data.identifier || '',
        IM_KEY: data.userSig || ''
      }
      let userInfo = data.userInfo || {}
      if (!userInfo.patientId) {
        userInfo.patientId = data.patientId
      }
      // state.token = data.token || null
      state.userIm = userIm
      state.userInfo = userInfo
      // api.setStorage('TOKEN', data.token || null)
      api.setStorage('USER_IM', JSON.stringify(userIm))
      api.setStorage('USER_INFO', JSON.stringify(userInfo) || '')
    },

    // 更新用户信息
    UPDATE_USER_INFO(state, data) {
      let userInfo = data.userInfo || {}
      state.userInfo = userInfo
      api.setStorage('USER_INFO', JSON.stringify(userInfo) || '')
    },

    // 更新用户im信息
    UPDATE_USER_IM(state, data) {
      state.userIm = data
      api.setStorage('USER_IM', JSON.stringify(data))
    },

    // 清除用户信息
    CLEAR_USER_INFO(state, retainItems = []) {
      state.openId = null
      state.token = null
      state.userIm = {}
      state.userInfo = {}
      let keys = Object.keys(localStorage)
      for(let key of keys) {
        if(retainItems.includes(key)) {
        } else {
          localStorage.removeItem(key)
        }
      }
    }
  }
}
