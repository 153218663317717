<template>
  <div class="appraise">
    <div class="title">
      服务评价
    </div>
    <!-- 等级列表 -->
    <div class="appraise-lv-list">
      <div v-for="(item,index) in appraiseList" :key="index" class="lv-item"
           :class="((form.appraiseId || defaultLv) == item.appraiseId) ? 'active' : ''" @click="changeAppraiseLv(item.appraiseId)">
        <img class="lv-icon" :src="((form.appraiseId || defaultLv) == item.appraiseId) ? item.actIcon : item.icon"  mode="aspectFill"/>
        <!-- <div class="lv-text">
          {{ item.appraiseDegree }}
        </div> -->
      </div>
    </div>
    <div v-if=" appraiseTip " class="merit-and-demerit">
      <div class="split-line"></div>
      <div class="text">
        {{ appraiseTip }}
      </div>
    </div>
    <!-- 优缺点列表 -->
    <div class="merit-and-demerit-list">
      <div v-for="(item,index) in meritAndDemerit "
           :key="index" class="merit-and-demerit-item"
           :class="{ 'active': isSpecial(item) }"
           @click="changeMeritAndDemerit(item.recordId)">
        {{ item.appraiseLabel }}
      </div>

<!--      <div v-for="(item,index) in meritAndDemerit " :key="index" class="merit-and-demerit-item" :class="jstools.includes(form.labelList, item.recordId) ? 'active' : ''" :value="item.recordId" @click="changeMeritAndDemerit">-->
<!--        {{ item.appraiseLabel }}-->
<!--      </div>-->
    </div>
    <!-- 评论 -->
    <div class="comment">
      <van-field
        v-model=" form.content "
        type="textarea"
        placeholder="我还有话想说"
        :autosize=" { minHeight: 50 } "
        :maxlength="200"
        :border="false"
      />
    </div>
    <div class="tips">
      本次为匿名评价，不会显示您的个人信息
    </div>
  </div>
</template>

<script>
import api from '@/utils/api'

export default {
  name: 'ZAppraise',
  props: {
    // 评价列表
    appraiseList: {
      type: Array,
      value: []
    },

    // 默认评价等级
    defaultLv: {
      type: [String, Number],
      value: ''
    }
  },

  data() {
    return {

      // 提示语
      appraiseTip: '',

      // 优缺点列表
      meritAndDemerit: [],

      // 表单
      form: {
        // 评价等级
        appraiseId: '',
        // 已选优缺点
        labelList: [],
        // 评价
        content: ''
      }
    }
  },

  // 监听器
  watch: {
    // 监听默认评价等级
    defaultLv: function(value) {
      // console.log(value, 333333333333)
      this.form.appraiseId = value || ''
      this.getMeritAndDemeritList()
    }
  },

  methods: {
    isSpecial(item) {
      // 检查item.recordId是否在form.labelList中
      return this.form.labelList.includes(item.recordId)
    },

    // 切换评价等级
    changeAppraiseLv(id) {
      let value = id || ''
      if(this.form.appraiseId != value) {
        this.form.labelList = []
      }
      this.form.appraiseId = value
      this.getMeritAndDemeritList()
    },

    // 获取优缺点列表
    getMeritAndDemeritList() {
      // console.log(this.appraiseList)
      let currentInfo = this.appraiseList.find((item) => {
        return item.appraiseId == this.form.appraiseId
      }) || {}
      this.meritAndDemerit = currentInfo.labelList || []
      this.appraiseTip = currentInfo.appraiseTip || ''
    },

    // 选择优缺点
    changeMeritAndDemerit(id) {
      let value = id || ''
      let selectedList = this.form.labelList
      // 判断是否已经选中
      if(selectedList.includes(value)) {
        selectedList.splice(selectedList.indexOf(value), 1)
      } else {
        selectedList.push(value)
      }
      this.form.labelList = selectedList
    },

    // 重置表单
    resetForm() {
      this.form = {
        appraiseId: '',
        labelList: [],
        content: ''
      }
    },

    // 提交表单
    submitForm() {
      let form = this.form
      let labelList = []

      if(!form.appraiseId) return api.toast('请先评价哦')

      this.meritAndDemerit.forEach((item) => {
        if(form.labelList.includes(item.recordId)) {
          labelList.push(item)
        }
      })

      this.$emit('submit', { ...form, labelList })
      // console.log('submitsubmit', { ...form, labelList })
    }
  }
}
</script>

<style lang="scss" scoped>
.appraise {
  padding: 30px 30px 60px 30px;
}

.appraise .title {
  font-size: 32px;
  font-weight: bold;
  color: #4D5150;
  margin-bottom: 25px;
}

.appraise-lv-list {
  display: flex;
  justify-content: space-around;
  /* margin-bottom: 50px; */
}

.lv-item {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lv-item .lv-icon {
  width: 150px;
  height: 150px;
  /* margin-bottom: 24px; */
}

.lv-item .lv-text  {
  font-size: 28px;
  color: #666
}

.lv-item.active .lv-text {
  font-weight: bold;
  color: #353535;
}

.merit-and-demerit {
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: 50px;
}

.merit-and-demerit .split-line {
  position: absolute;
  top: 20px;
  width: 100%;
  height: 0px;
  border-top: 0.5px solid #E4E5E7;
  margin: 0;
}

.merit-and-demerit .text {
  position: relative;
  z-index: 2;
  padding: 0 35px;
  line-height: 40px;
  font-size: 32px;
  font-weight: bold;
  color: #353535;
  text-align: center;
  background: #fff;
}

.merit-and-demerit-list {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin-bottom: 50px;
}

.merit-and-demerit-item {
  text-align: center;
  font-size: 26px;
  height: 60px;
  line-height: 60px;
  background: #F6F7FB;
  border-radius: 35px;
  border: 1px solid #E4E5E7;
}

.merit-and-demerit-item.active {
  background: #F3FDFF;
  border: 1px solid #4085D5;
}

.comment {
  background: #F6F7FB;
  border-radius: 10px;
  border: 1px solid #E4E5E7;
  font-size: 30px;
  margin-bottom: 30px;
}

.comment .van-cell {
  font-size: 30px;
  background: none;
}

.comment .van-cell .van-field__placeholder {
  color: #ABABAB;
  font-size: 30px;
}

.tips {
  font-size: 28px;
  color: #ABABAB;
}

</style>
