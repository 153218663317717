<!-- Banner组件 activityComponentType==='2' -->
<template>
  <!-- 轮播 -->
  <div class="banner">
    <van-swipe
      class="swipe"
      autoplay="3000"
      indicator-color="white"
      :style="{
        height: getHeight
      }"
    >
      <van-swipe-item
        v-for="(item, index) in config.bannerList"
        :key="index"
        @click="onJumpLink(item)"
        class="swipe-item"
      >
        <img
          class="swipe-img"
          :src="item.imageUrl"
        >
      </van-swipe-item>
    </van-swipe>
  </div>
</template>

<script>

import api from '@/utils/api'

export default {
  props: {
    // 配置信息
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {

    }
  },
  computed: {
    // 获取banner高度
    getHeight() {
      // return `${(this.config.imgHeight || 248) / 75}rem`
      return `${(this.config.imgHeight) / 2}px`
    }
  },
  methods: {
    // 跳转 linkType: 0内部路由｜1外部链接
    onJumpLink(item) {
      api.jumpLink(item)
    }
  }
}
</script>

<style lang="scss" scoped>
.banner {
  padding: 0 30px;
  margin: 30px 0;
  .swipe {
    height: 248px;
    border-radius: 16px;
    .swipe-item {
      .swipe-img {
        width: 100%;
        height: 100%;
        border-radius: 16px;
        object-fit: fill;
      }
    }
    ::v-deep .van-swipe__indicator {
      width: 14px;
      height: 14px;
      &:not(:last-child) {
        margin-right: 18px;
      }
    }
  }
}
</style>
