const patRouter = [
  {
    path: '/pat/sum',
    name: 'pat-sum',
    component: () => import('@/views/pat/sum.vue'),
    meta: {
      title: '咨询小结',
      navbar: false
    }
  },
  {
    path: '/pat/description',
    name: 'pat-description',
    component: () => import('@/views/pat/description.vue'),
    meta: {
      title: '病情描述',
      navbar: false
    }
  }
  // {
  //   path: '/pat/online-report',
  //   name: 'pat-online-report',
  //   component: () => import('@/views/pat/online-report.vue'),
  //   meta: {
  //     title: '在线报告',
  //     navbar: false
  //   }
  // }
]
export default patRouter

