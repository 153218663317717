import api from './api'

// 获取专题页配置
export function getClinicTopicPage(data) {
  return api.get({
    url: '/h5/home/page/getClinicTopicPage',
    data,
    loading: true,
    loadingText: '加载中'
  })
}

// 获取诊所文章列表
export function getClinicArticleList(data){
  return api.get({
    url: '/h5/home/content/getClinicArticleList',
    data,
    loading: true
  })
}

// 获取appId
export function getAppIdByClinicCode(data){
  return api.get({
    url: '/h5/common/base/getAppIdByClinicCode',
    data,
    loading: true
  })
}

// 获取appId（new）
export function getClinicConfigInfo(data){
  return api.get({
    url: '/bailuPub/open/getClinicConfigInfo',
    data,
    loading: true
  })
}

// 授权登录
export function authorizeLogin(data) {
  return api.post({
    url: '/h5/login/authorizeLogin',
    data,
    loading: true
  })
}

// 获取登录短信验证码
export function getVerificationCode(data) {
  return api.get({
    url: '/bailuPub/oauthCtrl/open/getValidateCode',
    data,
    loading: true,
    contentType: 'application/x-www-form-urlencoded'
  })
}

// 手机号登录
export function phoneLogin(data) {
  return api.post({
    url: '/h5/login/phoneLogin',
    data,
    loading: true
  })
}
// 新平台验证码登录
export function doPhoneValiCodeLogin(data) {
  return api.get({
    url: '/doPhoneValiCodeLogin',
    data,
    loading: true,
    contentType: 'application/x-www-form-urlencoded',
    version: '4.0.0'
  })
}
// 获取openId
export function appletsCode2Session(data) {
  return api.get({
    url: 'https://wechat.health.dev.zoenet.cn/hc-zyjkxpt/applets-code2Session',
    data,
    loading: true,
    contentType: 'application/x-www-form-urlencoded'
  })
}
// 新平台第三方登录
export function doThirdPartyAuthorLogin(data) {
  return api.get({
    url: '/doThirdPartyAuthorLogin',
    data,
    loading: true,
    contentType: 'application/x-www-form-urlencoded',
    isSign: true
  })
}

// 通过免登凭证换取登录准备信息
export function postLoginInfoByCert(data) {
  return api.post({
    url: '/bailuPub/open/postLoginInfoByCert',
    data,
    loading: true
  })
}

