import qs from 'qs'
import { Dialog } from 'vant'
import api from '@/utils/api'

export default {

  // 判断是否微信环境
  isWeixin() {
    let ua = window.navigator.userAgent.toLowerCase()
    if (ua.match(/MicroMessenger/i) == 'micromessenger') {
      return true
    } else {
      return false
    }
  },

  // 微信授权-新平台
  authPlatform(option) {
    let redirectPathStr = encodeURIComponent(encodeURIComponent(option.redirectPath))

    // redirectPathStr = redirectPathStr.indexOf('?') > -1 ? redirectPathStr : redirectPathStr + '?'
    // redirectPathStr = encodeURIComponent(option.redirectPath)

    // // 获取到code会在url后加上&code，所以加上?号兼容
    // redirectPathStr = redirectPathStr.indexOf('?') > -1 ? redirectPathStr : redirectPathStr + '?'
    // let param = qs.stringify({
    //   ...option.param
    // })
    // let paramStr = encodeURIComponent(param)
    // 只保留正式库的授权
    let wechatUrl = 'https://wechat.health.zoenet.cn/'
    // let wechatType = 'hc-xm-lqsq' // 莲前社区路由，预定义
    // let wechatType = 'hc-blyh' // 白鹭医护路由，预定义
    console.log('微信授权', option)
    let wechatType = option.authType
    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${option.appId}&redirect_uri=${wechatUrl}${wechatType}/blyhJump?paramUrl=${redirectPathStr}&response_type=code&scope=snsapi_base&state=&connect_redirect=1#wechat_redirect`
    window.location.href = url
  },
  // 微信授权
  auth(option = {}) {
    console.log(option.redirectPath)
    let redirectPathStr = encodeURIComponent(option.redirectPath)
    let param = qs.stringify({
      ...option.param
    })
    let paramStr = encodeURIComponent(param)
    let url = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${option.appId}&redirect_uri=${redirectPathStr}&response_type=code&scope=${option.scope || 'snsapi_base'}&state=${paramStr}#wechat_redirect`
    window.location.href = url
  },

  // 微信jsApi支付
  apiPay(option = {}, callback) {
    if(!WeixinJSBridge) return api.toast('请在微信环境下进行支付！')
    WeixinJSBridge.invoke('getBrandWCPayRequest', option,
      callback || function(res){
        console.log(res)
      }
    )
  },

  // 微信jsApi支付
  onShareAppMessage(option = {}, callback) {
    console.log('share0', option)

    if(!WeixinJSBridge) return api.toast('请在微信环境下进行！')
    WeixinJSBridge.invoke('onMenuShareAppMessage', {
        appId: option.appId, // 公众号名称，由商户传入
        imgUrl: option.thumbnail, // 分享图标
        link: 'https://clinic.dev.zoehospital.com/?clinicTopicCode=28&clinicCode=a98f7cad3b012168',
        // link: option.url, // 分享链接
        title: '服务详情' // 分享标题
      }, callback || function(res) {
        console.log('share', res)
      }
    )
  },

  // h5微信支付
  webPay(option = {}) {
    let u = navigator.userAgent
    let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1
    let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/)
    // 非ios直接进行跳转即可
    if(!isiOS) {
      window.location.href = option.path
      return
    }

    let redirectPathStr = ''
    let param = qs.stringify({
      isPaying: true,
      ...option.param
    })

    // 是否需要转向页面（如需携带其他参数需与转向路径搭配使用）
    if(option.redirectPath) {
      redirectPathStr = encodeURIComponent(`${option.redirectPath}?${param}`)
    }

    if(redirectPathStr) {
      window.location.href = `${option.path}&redirect_url=${redirectPathStr}`
    } else {
      window.location.href = option.path
    }
  },

  // 获取微信授权信息
  getWxInfo() {
    let url = location.search
    let theRequest = {}
    if (url.indexOf('?') != -1) {
      let str = url.substr(1)
      let strs = str.split('&')
      for (let i = 0; i < strs.length; i++) {
        theRequest[strs[i].split('=')[0]] = (strs[i].split('=')[1])
      }
    }
    return theRequest
  },

  // 校验支付结果弹窗
  payResultVerify(callback) {
      Dialog.confirm({
        message: '是否完成支付？',
        confirmButtonText: '我已支付',
        confirmButtonColor: 'rgb(3, 202, 216)',
        cancelButtonText: '还未支付',
        beforeClose: callback
      })
  }
}
