export default [
  {
    path: '/manage/list',
    name: 'manage-list',
    component: () => import('@/views/manage/list'),
    meta: {
      title: '健康管理'
    }
  },
  {
    path: '/manage/center',
    name: 'manage-center',
    component: () => import('@/views/manage/center'),
    meta: {
      title: '健康管理'
    }
  },
  {
    path: '/manage/buy',
    name: 'manage-buy',
    component: () => import('@/views/manage/buy'),
    meta: {
      title: '健康管理'
    }
  }
]
