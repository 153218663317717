export default [
  {
    path: '/offline/detail',
    name: 'offline-detail',
    component: () => import('@/views/offline/detail'),
    meta: {
      title: '服务详情'
    }
  },
  {
    path: '/offline/order',
    name: 'offline-order',
    component: () => import('@/views/offline/order'),
    meta: {
      title: '预约订单'
    }
  },
  {
    path: '/offline/result',
    name: 'offline-result',
    component: () => import('@/views/offline/result'),
    meta: {
      title: '预约成功'
    }
  }
]
