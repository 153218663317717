<template>
  <div class="child-wrap">
    <div
      class="child"
      @click="onJumpAddChild"
      v-if="config.childList.length==0||config.childList == null"
    >
      <div class="child-left">
        <div class="child-left_img">
          <img
            class="img"
            :src="require('@/assets/avatar.png')"
          />
        </div>
        <div class="child-left_info">
          <div class="title">您还未创建儿童档案</div>
          <div class="subtitle">快来测测宝宝成长的健康情况！</div>
        </div>
      </div>
      <div class="child-btn">去创建</div>
    </div>
    <van-swipe
      circular
      autoplay="5000"
      v-else
      class="child-swiper"
      :show-indicators="false"
      ref="swiper"
    >
      <van-swipe-item
        v-for="(itemSub, index) in config.childList"
        :key="index"
        @click="onJumpFile(itemSub)"
      >
        <div class="child-swiper_item">
          <div class="child-box">
            <div
              class="child-box_back"
              :class="itemSub.childGender=='2'?'child-box_back-girl':'child-box_back-boy'"
            />
            <img
              class="child-box_cartoon"
              :src="itemSub.cartoonImage"
            />
            <div class="child-box_bubble">
              <template v-if="itemSub.heightResult ==null || itemSub.weightResult ==null || itemSub.bmiResult == null">
                <span>记录我的健康档案吧！</span>
              </template>
              <span v-else>{{ (itemSub.growLevel==2 && itemSub.weightLevel==2 && itemSub.bmiLevel==2)?'我的健康数据非常好':'我的健康数据异常了~'}}</span>
            </div>
            <div
              class="child-box_main"
              :class="itemSub.childGender==2?'main-girl':'main-boy'"
            >
              <div class="top">
                <div class="name z-line1">{{itemSub.childName}}</div>
                <div class="age">{{itemSub.childAge}}岁</div>
                <div><img
                    class="sex"
                    :src="itemSub.genderIcon"
                  /></div>
              </div>
              <div class="box">
                <div class="box-col">
                  <div class="box-col_row1">
                    <div class="box-col_row1-title">身高</div>
                    <div
                      class="box-col_row1-label z-line1"
                      :class="`label-height-${itemSub.growLevel}`"
                    >{{itemSub.heightResult}}</div>
                  </div>
                  <div
                    v-if="itemSub.testHeight==null"
                    class="box-col_row2 box-col_row2-value"
                  >暂无</div>
                  <div
                    v-else
                    class="box-col_row2"
                  >
                    <div class="box-col_row2-num z-line1">{{ itemSub.testHeight }}</div>
                    <div class="box-col_row2-unit">CM</div>
                  </div>
                </div>
                <div class="box-col">
                  <div class="box-col_row1">
                    <div class="box-col_row1-title">体重</div>
                    <div
                      class="box-col_row1-label z-line1"
                      :class="`label-height-${itemSub.weightLevel}`"
                    >{{itemSub.weightResult}}</div>
                  </div>
                  <div
                    v-if="itemSub.testWeight==null"
                    class="box-col_row2 box-col_row2-value"
                  >暂无</div>
                  <div
                    v-else
                    class="box-col_row2"
                  >
                    <div class="box-col_row2-num z-line1">{{ itemSub.testWeight }}</div>
                    <div class="box-col_row2-unit">KG</div>
                  </div>
                </div>
                <div class="box-col">
                  <div class="box-col_row1">
                    <div class="box-col_row1-title">BMI</div>
                    <div
                      class="box-col_row1-label z-line1"
                      :class="`label-height-${itemSub.bmiLevel}`"
                    ><span class="z-line1">{{itemSub.bmiResult}}</span></div>
                  </div>
                  <div
                    v-if="itemSub.bmiValue==null"
                    class="box-col_row2 box-col_row2-value"
                  >暂无</div>
                  <div
                    v-else
                    class="box-col_row2"
                  >
                    <div class="box-col_row2-num z-line1">{{ itemSub.bmiValue }}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </van-swipe-item>
    </van-swipe>

  </div>
</template>

<script>

import api from '@/utils/api'
import auth from '@/utils/auth'

export default {
  props: {
    // 配置信息
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {

    }
  },

  methods: {
    // 跳转 linkType: 0内部路由｜1外部链接
    onJumpLink(item) {
      api.jumpLink(item)
    },

    // 跳转-添加儿童
    onJumpAddChild() {
      if (this.$store.getters.getToken) {
        api.jumpPage('kids-child-add', {
          ...this.config
        })
      } else {
        this.onJumpLogin()
      }
    },

    // 跳转-身高档案
    onJumpFile(item) {
      api.jumpPage('kids-growth-record', {
        childId: item.childId
      })
    },

    // 登录
    async onJumpLogin() {
      try {
        const info = await auth() || {}
        if (info.url) {
          // 有回调地址的话直接强制重定向
          window.location.replace(info.url)
        } else {
          // 正常进入页面，用于直接通过openId进行登录
          this.$router.go(0)
        }
      } catch (err) {
        if (err?.url) {
          // 强制重定向页面的话
          window.location.replace(err.url)
        } else {
          // 走手机号登录流程
          await this.$router.replace({
            path: '/login'
          })
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
/* 儿童模块 */
.child-wrap {
  border-radius: 16px 16px 16px 16px;
  margin: 30px 30px;
}

.child {
  width: 690px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  min-height: 150px;
  padding: 20px;
  background-color: #ffffff;
  border-radius: 16px 16px 16px 16px;
}

.child-left {
  display: flex;
  align-items: center;
  margin-right: 5px;
}

.child-left_img {
  flex: none;
  position: relative;
  margin-right: 20px;
}

.child-left_img .img {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: block;
}

.child-left_info {
}

.child-left_info .title {
  color: #353535;
  font-size: 36px;
  font-weight: bold;
}

.child-left_info .subtitle {
  margin-top: 5px;
  font-size: 28px;
  color: #ababab;
}

.child-btn {
  flex: none;
  font-size: 28px;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 130px;
  height: 50px;
  background: #03cad8;
  border-radius: 10px 10px 10px 10px;
}

.child-swiper {
  height: 355px;
}

.child-swiper_item {
  //margin-bottom: 30px;
}

.child-box {
  display: flex;
  flex-direction: row;
  border-radius: 16px 16px 16px 16px;
  position: relative;
  height: 352px;
  margin-left: 1px;

  .child-box_cartoon {
    position: absolute;
    right: 0;
    top: 0;
    width: 205px;
    height: 66%;
    object-fit: fill;
    z-index: 1;
  }

  .child-box_back {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 690px;
    height: 280px;
    border-radius: 16px;
  }

  .child-box_back-girl {
    background-image: url('~@/assets/kids/home-kids_back-girl.png');
    background-size: cover;
    width: 690px;
    height: 280px;
    border-radius: 16px;
  }

  .child-box_back-boy {
    background-image: url('~@/assets/kids/home-kids_back-boy.png');
    background-size: cover;
    width: 690px;
    height: 280px;
    border-radius: 16px;
  }

  .child-box_bubble {
    width: 210px;
    height: 50px;
    background: #ffffff;
    border-radius: 8px;
    position: absolute;
    right: 200px;
    top: 85px;
    z-index: 3;
    font-size: 20px;
    color: #353535;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .child-box_bubble::after {
    content: '';
    width: 0px;
    height: 0px;
    border: 10px solid;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-top: 10px solid #ffffff;
    /*给绝对定位，根据需求设置三角形的位置*/
    position: absolute;
    top: 50px;
    left: 160px;
  }

  .child-box_main {
    .top {
      width: 410px;
      height: 100px;
      display: flex;
      align-items: flex-end;
      padding: 20px 20px 30px 20px;

      .name {
        font-weight: 800;
        font-size: 42px;
        color: #353535;
      }

      .age {
        flex: none;
        font-size: 28px;
        color: #353535;
        margin-left: 10px;
      }

      .sex {
        flex: none;
        width: 32px;
        height: 33px;
        margin-left: 10px;
        vertical-align: text-top;
      }
    }

    .box {
      width: 650px;
      height: 128px;
      background: #ffffff;
      border: 1px solid #fff5f5;
      border-radius: 16px;
      margin: 0 20px 15px 20px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      .box-col {
        flex: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 128px;
        padding: 28px 0px 15px 30px;
        width: 33.3%;
      }

      .box-col_row1 {
        display: flex;
        align-items: center;

        .box-col_row1-title {
          font-size: 26px;
          color: #666666;
        }

        .box-col_row1-label {
          text-align: center;
          color: #ffffff;
          font-size: 20px;
          margin-left: 10px;

          min-width: 66px;
          max-width: 100px;
          height: 30px;
          border-radius: 8px 8px 8px 8px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 5px;
        }
      }

      .box-col_row2 {
        display: flex;
        align-items: baseline;
        margin-top: 5px;
      }

      .box-col_row2-value {
        font-weight: bold;
        font-size: 32px;
        color: #353535;
        margin-top: 15px;
      }

      .box-col_row2-num {
        font-weight: bold;
        font-size: 38px;
        color: #353535;
        margin-right: 8px;
      }

      .box-col_row2-unit {
        flex: none;
        font-size: 22px;
        color: #ababab;
      }
    }
  }

  .main-girl {
    position: absolute;
    bottom: 0;
    left: 0px;
    background-image: url('~@/assets/kids/home-kids_top-girl.png');
    background-size: cover;
    width: 690px;
    height: 255px;
    z-index: 2;
    border-radius: 16px;
  }

  .main-boy {
    position: absolute;
    bottom: 0;
    left: 0px;
    background-image: url('~@/assets/kids/home-kids_top-boy.png');
    background-size: cover;
    width: 690px;
    height: 255px;
    z-index: 2;
    border-radius: 16px;
  }
}

.label-height-0,
.label-weight-0,
.label-bmi-0 {
  background: #316cf5;
}

.label-height-1 {
  background: #5babf7;
}

.label-height-2,
.label-weight-1,
.label-bmi-1 {
  background: #31c8b4;
}

.label-height-3,
.label-bmi-2 {
  background: #f1ab51;
}

.label-height-4,
.label-weight-2,
.label-bmi-3 {
  background: #ed655e;
}
</style>
