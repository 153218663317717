export default [
  {
    path: '/patient/manage',
    name: 'patient-manage',
    component: () => import('@/views/patient/manage'),
    meta: {
      title: '就诊人管理',
      whitelist: true
    }
  },
  {
    path: '/patient/add',
    name: 'patient-add',
    component: () => import('@/views/patient/add'),
    meta: {
      title: '就诊人管理',
      whitelist: true
    }
  }
]
