export default [
  {
    path: '/course/set',
    name: 'course-set',
    component: () => import('@/views/course/set'),
    meta: {
      title: '健康科普'
    }
  },
  {
    path: '/course/series',
    name: 'course-series',
    component: () => import('@/views/course/series'),
    meta: {
      title: '健康科普'
    }
  },
  {
    path: '/course/detail',
    name: 'course-detail',
    component: () => import('@/views/course/detail'),
    meta: {
      title: '健康科普'
    }
  },
  {
    path: '/course/article',
    name: 'course-article',
    component: () => import('@/views/course/article'),
    meta: {
      title: '健康科普'
    }
  },
  {
    path: '/course/my',
    name: 'course-my',
    component: () => import('@/views/course/my'),
    meta: {
      title: '我的课程'
    }
  },
  {
    path: '/course/pay',
    name: 'course-pay',
    component: () => import('@/views/course/pay'),
    meta: {
      title: '订单详情'
    }
  }
]
