export default [
  {
    path: '/vip/buy',
    name: 'vip-buy',
    component: () => import('@/views/vip/buy'),
    meta: {
      title: '会员详情'
    }
  },
  {
    path: '/vip/center',
    name: 'vip-center',
    component: () => import('@/views/vip/center'),
    meta: {
      title: '会员中心'
    }
  },
  {
    path: '/vip/rights',
    name: 'vip-rights',
    component: () => import('@/views/vip/rights'),
    meta: {
      title: '权益详情'
    }
  }
]
