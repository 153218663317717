import Vue from 'vue'
import Event from '@/plugin/tencentIM/event'
import * as RongIMLib from '@rongcloud/imlib-v4'
import API from '@/utils/api'
import $store from '@/store'
import { getImUserData, sendImMsg, getLatestMessageRecord } from '@/api/rong-cloud'
import { getMessageRecord } from '@/api/chat'
import { postUploadFileBase64 } from '@/api/common'

let $event = Vue.prototype.$event = new Event()
let $rongCloud = Vue.prototype.$rongCloud || {}
let $rongImClient = Vue.prototype.$rongImClient
let $rongCloudInit = Vue.prototype.$rongCloudInit || false

// msgType 类型： TIMTextElem 普通文本   TIMImageElem 图片消息  TIMSoundElem 语音消息  TIMCustomElem 卡片消息
const MessageType = {
  Text: 'TIMTextElem',
  Image: 'TIMImageElem',
  Audio: 'TIMSoundElem',
  Custom: 'TIMCustomElem'
}

export function initIM() {
  if ($rongCloudInit) {
    return
  }

  // 获取我方详细数据
  // $rongCloud.getMyProfile = async function() {}

  // 获取对方详细数据
  // $rongCloud.getUserProfile = async function({ userIDList }) {}

  // 获取会话消息
  // $rongCloud.getMessageList = async function({ conversationID, count, nextReqMessageID }) {
  // }

  // 创建文本消息
  $rongCloud.createTextMessage = function({ to, payload }) {
    return {
      msgType: MessageType.Text,
      fromAccount: $store.getters.getUserIm.IM_ID,
      toAccount: to,
      msgContent: payload.text
    }
  }

  // 创建自定义消息
  // $rongCloud.createCustomMessage = function({ to, conversationType, payload }) {
  //   return {
  //     msgType: MessageType.Custom,
  //     fromAccount: $store.getters.getUserIm.IM_ID,
  //     toAccount: to,
  //     msgContent: payload.text,
  //   }
  // }

  // 创建图片消息
  $rongCloud.createImageMessage = function({ to, payload, onProgress }) {
    return {
      msgType: MessageType.Image,
      fromAccount: $store.getters.getUserIm.IM_ID,
      toAccount: to,
      _ready: async(msgObj) => {
        const images = await uploadImages({
          files: [payload.file],
          onProgress
        })

        msgObj.imageFormat = images.length
        msgObj.imageInfoArray = JSON.stringify(images.flatMap(item => ([
          {
            Type: 1,
            Size: item.size,
            Height: item.height,
            Width: item.width,
            URL: item.url
          },
          {
            Type: 2,
            Size: item.size,
            Height: item.height,
            Width: item.width,
            URL: item.url
          },
          {
            Type: 3,
            Size: item.size,
            Height: item.height,
            Width: item.width,
            URL: item.url
          }
        ])))
      }
    }
  }

  // 发送消息
  $rongCloud.sendMessage = async function(message) {
    if (message._ready) {
      // 上传图片
      try {
        await message._ready(message)
      } catch (e) {
        console.error(e)
        throw e
      }
    }

    delete message._ready

    await sendImMsg(message)
    return {
      data: {}
    }
  }

  // 设置会话已读
  $rongCloud.setMessageRead = function({ fromAccount, toAccount }) {
    // updateReadMsg({
    //   fromAccount,
    //   toAccount
    // })
  }

  // 拉取会话列表
  // $rongCloud.getConversationList = async function() {
  //   return {}
  // }

  Vue.prototype.$rongCloud = $rongCloud
  Vue.prototype.$rongCloudInit = $rongCloudInit = true
}

async function getImageInfo(file) {
  const fr = new FileReader()
  const base64 = await new Promise((resolve, reject) => {
    fr.readAsDataURL(file)
    fr.onload = function(e) {
      resolve(e.target.result)
    }
    fr.onerror = reject
  })

  const img = new Image()
  await new Promise((resolve, reject) => {
    img.src = base64
    img.onload = resolve
    img.onerror = reject
  })

  return img
}

async function uploadImages({ files, onProgress }) {
  let progress = 0
  onProgress(0)
  console.log('upload images', files)
  return await Promise.all(files.map(async file => {
    const res = await postUploadFileBase64({
        base64Str: file.base64Str,
        fileType: file.fileType,
        saveType: file.saveType
    })
    progress++
    onProgress(progress / files.length)

    const img = await getImageInfo(file.file.file)

    return {
      url: res.data.fileUrl,
      size: file.file.file.size,
      width: img.width,
      height: img.height
    }
  }))
}

// 接口请求最新的消息作为消息响应
async function getLatestMessage(fromAccount, toAccount, msgKey) {
  const res = await getLatestMessageRecord({
    fromAccount,
    toAccount,
    msgKey
  })

  return res.data.messageList[res.data.messageList.length - 1]
}

export async function loginIM() {
  const res = await getImUserData({
    userId: $store.getters.getUserInfo.userId,
    patientId: $store.getters.getUserInfo.patientId
  })

  $rongImClient = RongIMLib.init({ appkey: res.data.appKey })

  let user

  $rongImClient.watch({
    status(res) {
      console.log('连接状态变化1', res)
      const msg = {
        3: '网络不可用, SDK 内部会自动重连',
        4: 'Socket 不可用, SDK 内部会自动重连',
        6: '被其他端踢掉',
        9: '用户被封禁',
        12: '域名错误'
      }[res.status]
      switch (res.status) {
        // case 3:
        // case 4:
        //   return API.toast(msg)
        case 6:
        case 9:
        case 12:
          return API.showDialog({
            title: '链接失败',
            message: msg
          })
      }
    },
    conversation() {
      if ($rongCloudInit) {
        $event.emit('initImList') // 会话列表的监听函数
      }
    },
    async message(event) {
      console.log('message received', event)
      const isSend = event.message.messageDirection === 1 // 发送的消息
      const content = JSON.parse(event.message.content.content)
      const msgKey = content.msgKey
      if (!msgKey) {
        return
      }

      let msgObj
      if (isSend) {
        msgObj = await getLatestMessage(event.message.senderUserId, event.message.targetId, msgKey)
      } else {
        msgObj = await getLatestMessage(user.id, event.message.senderUserId, msgKey)
      }
      if (msgObj) {
        if (isSend) {
          $event.emit('send', {
            data: {
              message: msgObj
            }
          }) // 会话列表的监听函数
        } else {
          $event.emit('received', {
            data: [msgObj]
          }) // 会话列表的监听函数
        }
      }
    }
  })

  try {
    user = await $rongImClient.connect({ token: res.data.patientImToken })
    // if(location.host !== "public.health.zoenet.cn") util.toast(`链接成功, 链接用户 id 为: ${user.id}`);
    console.log('链接成功, 链接用户 id 为: ', user)
    $event.emit('imReady') // im sdk已准备
    $event.emit('initImDetail')// 聊天记录的监听函数
    Vue.prototype.$rongImClient = $rongImClient
  } catch (error) {
    console.log('链接失败: ', error.code, error.msg)
    switch (error.code) {
      case 34001:
      case 33006:
        break
      default:
        // API.showDialog({ content: '链接失败:' + JSON.stringify(error) })
    }
  }
}

/**
 * 切换IM
 * @param {Object} res 数据
 * @param {Object} isWaitReady 是否需要等待ready后
 */
export async function exChangeLoginIm(res, isWaitReady) {
  if ($store.getters.getUserIm.IM_ID) {
    $rongImClient.unwatch()
    await $rongImClient.disconnect()
    console.log('断开链接成功')
  }

  let data = res.data || {}
  let tokenIm = {
      IM_ID: data.identifier,
      IM_KEY: data.userSig
  }
  $store.commit('UPDATE_USER_IM', tokenIm)

  initIM()

  await loginIM(isWaitReady)

  return true
}
