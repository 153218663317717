export default [
  {
    path: '/article/list',
    name: 'article-list',
    component: () => import('@/views/article/list'),
    meta: {
      title: '科普文章'
    }
  },
  {
    path: '/article/detail',
    name: 'article-detail',
    component: () => import('@/views/article/detail'),
    meta: {
      title: '文章详情'
    }
  }
]
