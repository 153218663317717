import api from '@/api/api'

// 获取融云用户token信息-用于登录
export function getImUserData(data) {
  return api.post({
    url: '/h5/rongYunIM//home/getImUserData',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 发送聊天信息
export function sendImMsg(data) {
  return api.post({
    url: '/h5/rongYunIM//home/sendImMsg',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

// 发送聊天信息
export function getLatestMessageRecord(data) {
  return api.post({
    url: '/h5/common/imMessage/getLatestMessageRecord',
    data,
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}
