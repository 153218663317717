<template>
  <div class="doc-card-wrap">
  <!-- (0否，1是):isExpert -->
    <div class="box"
         :class="item.isExpert==1?'box-vip':''"
         v-for="(item,index) in arrDoc"
         :key="index"
         @click="onJumpHome(item)"
    >
      <div class="box-left">
        <van-image
          :src="item.doctorImage"
          class="box-left_img"
          :class="item.isExpert==1?'box-left_img-vip':''"
          round
          fit="cover"
        />
        <img v-if="item.isExpert==1" class="box-left_expert" :src="require('@/assets/doctor_expert.png')"/>
      </div>

      <div class="doc-info">
        <div class="doc-info_top">
          <span class="doc-info_top-name">{{item.doctorName}}</span>
          <span class="doc-info_top-rank">{{item.doctorTitle}}</span>
          <div class="doc-info_top-label level-label" v-if="item.organizationLevel==null||item.organizationLevel==''?false:true">{{item.organizationLevel}}</div>

<!--          <div class="doc-info_top-name">{{item.doctorName}}</div>-->
<!--          <div class="doc-info_top-info">-->
<!--            <span class="doc-info_top-rank">{{item.doctorTitle}}</span>-->
<!--            <span class="doc-info_top-label level-label" v-if="item.organizationLevel==null||item.organizationLevel==''?false:true">{{item.organizationLevel}}</span>-->
<!--          </div>-->
<!--          <span class="doc-info_top-label allow-label" v-if="item.allowPrescription">可开处方</span>-->
        </div>
        <div class="doc-info_dept">
          <span class="middle">{{item.deptName||item.clinicName}}</span>
          <span class="split"></span>
          <span class="middle">{{item.organizationName}}</span>
        </div>
        <div class="doc-info_good z-line2" v-if="item.doctorSkill" >
          <img class="doc-info_good-img" :src="require('@/assets/icons/specialty.png')"/>
          <span>{{item.doctorSkill}}</span>
        </div>
<!--        <div class="doc-info_service">-->
<!--          <div class="doc-info_service-item" v-for="(item,index) in item.productInfos" >-->
<!--            {{item}}-->
<!--          </div>-->
<!--        </div>-->
        <div class="doc-info_bottom">
          <div class="doc-info_bottom-left">
            <div class="doc-info_bottom-price" v-if="item.isService">
              <div class="price">
                <span class="font-red font-30">¥ </span>
                <span class="font-red font-bold marl8">{{item.price}} </span>
                <span class="font-grey font-28 marl8">起</span>
                <div class="price-origin" v-if="item.realPrice">
                  ¥ {{item.realPrice}} 起
                </div>
              </div>
              <div class="doc-info_top-label activity-label" v-if="item.activityName">{{item.activityName}}</div>
            </div>
          </div>
          <div class="doc-info_bottom-btn">向我咨询</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api'

export default {
  name: 'ZDocCard',
  props: {
    arrDoc: {
      type: Array,
      default: []
    }
  },

  data() {
    return {
    }
  },

  methods: {
    onJumpHome(item){
      api.jumpPage('doctor-home', {
        doctorCode: item.doctorCode
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.marl8 {
  margin-left: 8px;
}
.box-vip {
  background: linear-gradient(360deg, #FFFFFF 60%, #FFF4E4 100%);

}
.box {
  width: 690px;
  min-height: 250px;
  border-radius: 16px 16px 16px 16px;
  background-color: #FFFFFF;
  opacity: 1;
  margin: 20px auto;
  padding: 30px 20px;
  display: flex;
  align-items: flex-start;

  .box-left {
    position: relative;
  }

  .box-left_expert {
    position: absolute;
    left: 5px;
    bottom: 2px;
    width: 100px;
  }

  .box-left_img {
    flex: none;
    width: 110px;
    height: 110px;
    border-radius: 50%;
    margin-right: 16px;
  }

  .box-left_img-vip {
    border: 1.5px solid #F1BA6A;
  }

  .doc-info{
    flex: 1;
    .doc-info_top{
      font-size: 36px;
      color: #000000;
      margin-bottom: 15px;
    }
    .doc-info_top-name{
      font-weight: 700;
      margin-right: 12px;
      font-size: 36px;
      flex: none;
    }
    .doc-info_top-rank{
      flex: none;
      font-size: 30px;
      font-weight: 700;
      margin-right: 6px;
      color: #333333;
      text-align: left;
      vertical-align: baseline;
    }
    .doc-info_top-label{
      flex: none;
      display: inline-block;
      text-align: center;
      padding: 1px 6px 1px 6px;
      border-radius: 6px;
      font-size: 20px;
      margin-left: 10px;
      vertical-align: middle;
    }
    .level-label{
      background: #E5F9F8;
      border: 0.5px solid #2FB7BF;
      color: #2FB7BF;
    }
    .allow-label{
      background: #FAF7F2;
      border: 0.5px solid #DFC8AE;
      color: #B1722E;
    }

    .activity-label{
      background: #e54d3824;
      border: 0.5px solid #ECB0AA;
      color: #E54D38;
      vertical-align: middle;
    }

    .doc-info_dept{
      font-size: 28px;
      line-height: 43px;
      color: #666666;
      text-align: left;
      margin-bottom: 16px;
    }
    .middle{
      vertical-align: middle;
    }
    .split{
      position: relative;
      margin: 0 10px;
      vertical-align: middle;
    }
    .split::after{
      position: absolute;
      right: 0;
      top: 0;
      content: '';
      width: 1px;
      height: 100%;
      border-right: 0.5px solid #d4d3d3;
      transform: scaleY(0.5);
    }
    .doc-info_good{
      line-height: 40px;
      font-size: 28px;
      color: #353535;
      margin-bottom: 16px;
      text-align: left;
    }
    .doc-info_good-img {
      display: inline-block;
      width: 58px;
      height: 32px;
      vertical-align: middle;
      margin-right: 10px;
      padding-bottom: 6px;
    }
    .doc-info_service{
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      font-size: 26px;
      margin-bottom: 7px;
    }

    .doc-info_service-item{
      color: #666666;
      height: 46px;
      border-radius: 10px;
      border: 0.5px solid #d4d3d3;
      margin-bottom: 10px;
      padding: 0 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 20px;
      margin-right: 10px;
    }

    .doc-info_bottom{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;
    }
    .doc-info_bottom-left {
    }

    .doc-info_bottom-price{
      font-size: 36px;
      display: flex;
      align-items: baseline;

      .price {
        display: flex;
        align-items: baseline;
        color: #F63F0D;
      }

      .price-origin {
        text-decoration: line-through;
        color: #ABABAB;
        font-size: 26px;
        margin-left: 10px;
      }

    }
    .font-red{
      color: #F63F0D;
    }
    .font-grey{
      color: #999999;
    }
    .font-bold{
      font-weight: bold;
    }
    .font-30{
      font-size: 30px;
    }
    .font-28{
      font-size: 28px;
    }

    .doc-info_bottom-btn{
      flex: none;
      width: 162px;
      height: 60px;
      background: #4085D5;
      border: 1px solid #337DD3;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 24px;
      color: #FFFFFF;
      border-radius: 50px;
      margin-left: 10px;
    }
  }

}

.font-blue {
  color: #337DD3;
}

.font-grey {
  color: #666666;
}

.flex-center {
  align-items: center;
}
</style>
