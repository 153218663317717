<!-- 功能区 styleType--样式选择（0-1:N，1-N:1，2-其他数量 activityComponentType==='5' -->
<template>
  <div class="functional-area">
    <!-- 2-其他数量 -->
    <div class="m-function" v-if="config.styleType==2">
      <van-image
        lazy-load
        class="item"
        show-loading
        fit="cover"
        v-for="(itemSub,index) in config.functionList"
        :src="itemSub.imageUrl"
        :key="index"
        @click="onJumpLink(itemSub)"
        :style="`height: ${config.componentHeight/2|| '68'}px`"/>
    </div>
    <!-- 0-1:N -->
    <div class="m-function" v-if="config.styleType==0">
      <van-image lazy-load class="function-column" show-loading fit="cover" :src="config.functionList[0].imageUrl" @click="onJumpLink(config.functionList[0])" :style="`height: ${config.maxHeight/2}px`"/>
      <div class="function-row">
        <van-image
          lazy-load
          class="function-row_item"
          show-loading
          fit="cover"
          v-for="(itemSub,index) in config.arrImg"
          :src="itemSub.imageUrl"
          :key="index"
          @click="onJumpLink(itemSub)"
          :style="`height: ${config.componentHeight/2|| '68'}px`"/>
      </div>
    </div>
    <!-- 1-N:1 -->
    <div class="m-function" v-if="config.styleType==1">
      <div class="function-row">
        <van-image
          lazy-load
          class="function-row_item"
          show-loading
          fit="cover"
          v-for="(itemSub,index) in config.arrImg"
          :src="itemSub.imageUrl"
          :key="index"
          @click="onJumpLink(itemSub)"
          :style="`height: ${config.componentHeight/2|| '68'}px`"/>
      </div>
      <van-image
        lazy-load
        class="function-column"
        show-loading fit="cover"
        :src="config.functionList[0].imageUrl"
        @click="onJumpLink(config.functionList[0])"
        :style="`height: ${config.maxHeight/2}px`"/>
    </div>
  </div>
</template>

<script>

import api from '@/utils/api'

export default {
  props: {
    // 配置信息
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {

    }
  },

  computed: {
    // 获取列表
    getList() {
      return (type, list) => {
        if (type === 0) {
          return [[list[0]], [...list.slice(1, list.length)]]
        } else {
          return [[...list]]
        }
      }
    }
  },

  methods: {
    // 跳转 linkType: 0内部路由｜1外部链接
    onJumpLink(item) {
      api.jumpLink(item)
    }
  }
}
</script>

<style lang="scss" scoped>
::v-deep {
  .van-image__img {
    border-radius: 8px;
  }
}

.functional-area {
  padding: 0 30px;
  margin: 50px 0 30px 0;
  .default-layout {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-row-gap: 20px;
    grid-column-gap: 20px;
    .functional-item {
      width: 335px;
      height: 160px;
      background: #f7f0e6;
      border-radius: 16px;
    }
  }
  // 特殊布局
  .three-layout {
    display: flex;
    justify-content: space-between;
    flex-shrink: 0;
    .left {
      height: 290px;
      .functional-item {
        object-fit: cover;
        width: 335px;
        height: 100%;
        background: #f9f6f1;
        border-radius: 16px;
      }
    }
    .right {
      height: 290px;
      height: auto;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      .functional-item {
        object-fit: cover;
        width: 335px;
        height: 135px;
        background: #f9f6f1;
        border-radius: 16px;
      }
    }
  }
}

.m-function {
  display: grid;
  grid-template-columns: repeat(2, 335px);
  column-gap: 18px;
  row-gap: 16px;
  width: 690px;
  justify-content: space-between;

  .item {
    height: 292px;
    width: 335px;
    border-radius: 8px;
  }

  .function-column {
    width: 335px;
    border-radius: 8px;
  }

  .function-row {
    width: 335px;
    display: flex;
    flex-direction: column;
  }

  .function-row_item{
    width: 100%;
    height: 136px;
    border-radius: 8px;
    margin-bottom: 16px;
  }

  .function-row_item:last-child{
    margin-bottom: 0px;
  }
}
</style>
