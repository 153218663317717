<template>
  <!-- 文章专区 -->
  <div class="article-box">
    <!-- 专区标题 -->
    <title-partition
      v-if="config.title"
      :config="config"
    ></title-partition>
    <div class="article">
      <!-- 查看更多 -->
      <div
        v-if="showMore && getArticleList.length"
        class="more"
        @click="goToList"
      >
        查看更多<van-icon name="arrow" />
      </div>
      <!-- 图片类型 -->
      <div v-if="articleType == '2'">
        <img
          class="image"
          :src="config.imageUrl"
        >
      </div>
      <!-- 文章类型 -->
      <template v-else>
        <z-empty v-if="!getArticleList.length"></z-empty>
        <van-list
          v-else
          v-model="loading"
          :finished="getFinishStatus"
          :finished-text="getFinishedText"
          @load="onLoad"
        >
          <div class="list">
            <card
              v-for="item in getArticleList"
              :key="item.id"
              :config="item"
            >
            </card>
          </div>
        </van-list>
      </template>

    </div>
  </div>
</template>

<script>
import api from '@/utils/api'
import titlePartition from '@/views/components/title-partition/index.vue'
import card from '@/views/components/card/index.vue'
import { getClinicArticleList } from '@/api/index'

export default {
  components: {
    titlePartition,
    card
  },
  props: {
    // 配置信息
    config: {
      type: Object,
      default: () => {
        return {}
      }
    },

    // 显示查看更多按钮
    showMore: {
      type: Boolean,
      default: true
    },

    // 类型 // 1为文章类型，2为图片，默认文章类型
    articleType: {
      type: [String, Number],
      default: '1'
    },

    // 是否分页加载
    isLoad: {
      type: Boolean,
      default: false
    },

    // 加载完成文案
    finishedText: {
      type: String,
      default: '没有更多了'
    }
  },
  data() {
    return {
      list: [], // 列表
      pageNum: 1, // 页码
      pageSize: 10, // 每页数量
      loading: false, // 列表加载状态
      finished: false, // 是否加载完所有数据
      isLoading: false// 判断是否正在加载，避免重复加载
    }
  },

  computed: {
    // 获取文章列表
    getArticleList() {
      return this.config.articleList || this.list
    },

    // 获取加载完成状态
    getFinishStatus() {
      // 如无需触底分页加载直接加载完全部
      return !this.isLoad || this.finished
    },

    // 获取加载完全部数据文案
    getFinishedText() {
      return this.isLoad ? this.finishedText : ''
    }
  },

  created() {

  },

  methods: {
    // 触底加载列表
    onLoad() {
      this.getList()
    },

    // 重置列表
    reset() {
      this.finished = false
      this.loading = false
      this.isLoading = false
      this.pageNum = 1
      this.list = []
      this.getList()
    },

    // 获取列表
    async getList() {
      if (!this.isLoad) return
      if (this.isLoading) return
      this.isLoading = true
      try {
        let param = {
          clinicZoneId: this.config.clinicZoneId,
          clinicSortId: this.config.clinicSortId || null,
          pageNum: this.pageNum,
          pageSize: this.pageSize
        }
        const res = await getClinicArticleList(param)
        const { data, totalCount } = res
        this.list = [...this.list, ...(data || [])]
        // 判断是否已经加载完所有数据
        if (totalCount <= this.list.length) {
          this.finished = true
        } else {
          this.pageNum++
        }
        this.loading = false
        this.isLoading = false
      } catch (err) {

      }
    },

    // 跳转文章列表
    goToList() {
      api.jumpPage('article-list', { ...this.config })
    }
  }
}
</script>

<style lang="scss" scoped>
.article-box {
  margin: 50px 0 30px 0;
  .article {
    margin: 0 30px 20px 30px;
    padding: 30px;
    background: #ffffff;
    border-radius: 16px;
    border: 1px solid #eee5de;
    .image {
      width: 100%;
      object-fit: cover;
      border-radius: 16px;
    }
    .more {
      font-size: 28px;
      color: #969799;
      text-align: right;
      margin-bottom: 15px;
    }
    .list {
    }
  }
}
</style>
