import qs from 'qs'
import wx from '@/utils/wx-utils'
import store from '@/store'
import { getClinicConfigInfo, authorizeLogin, appletsCode2Session, doThirdPartyAuthorLogin } from '@/api/index'
import api from '@/utils/api'
import router from '@/router'

// 去除url查询参数
// function deleteQueryString(url, name){
// 	const reg = new RegExp('(|&)' + name + '=([^&/#]*)')
// 	const str = url.match(reg)
// 	if (str && str[0]) {
// 		const newUrl = url.replace(str[0], '')
// 		return newUrl
// 	}
// 	return url
// }

// 获取appId && authType
function getAppId() {
  return new Promise(async(resolve, reject) => {
    // 社区编号
    const clinicCode = store.getters.getClinicCode
    try {
      const res = await getClinicConfigInfo({ clinicCode })
      console.log('获取appId', res)
      if(res.data.appId) {
        resolve(res.data)
      } else {
        reject('appid')
      }
    } catch(err) {
      reject('appid')
    }
  })
}

// 获取token
function getToken(openId = '') {
  return new Promise(async(resolve, reject) => {
    if(!openId) return reject(false)

    let param = {
      clinicCode: store.getters.getClinicCode,
      openId
    }

    try {
      let tokenRes = null
      const res = await authorizeLogin(param)
      if (res.data.userInfo) {
        let params = {
          mobile: res.data.userInfo.phoneNumber,
          openId,
          appCode: 'HC_BL_WX'
        }
        tokenRes = await doThirdPartyAuthorLogin(params)
      }

      resolve({ ...res.data, token: tokenRes ? tokenRes.data.tokenId : '' })
    } catch(e) {
      reject(false)
    }
  })
}

// 登录流程
export default function(redirectInfo = {}) {
  return new Promise(async(resolve, reject) => {
    // 转向路径
    const redirectPath = redirectInfo.path || '/'
    // 转向参数
    const redirectQuery = qs.stringify({ clinicCode: store.getters.getClinicCode, ...redirectInfo.query })

    // 回调页面路径
    let url = `${window.location.origin}${window.location.pathname}#${redirectPath}${redirectQuery ? `?${redirectQuery}` : ''}`

    // 微信环境下
    if(wx.isWeixin()) {
      // 拿到openId
      let openID = api.getUrlParam({
        key: 'openID'
      })
      if (openID) {
        // 保存openId
        store.commit('SET_OPENID', openID)
        // 通过openId获取token
        const data = await getToken(openID)
        // 判断是否返回token，如果有返回的话直接静默登录，没有的话就去进行手机号登录
        if(!data.token) {
          // 首次登录需要走手机号登录流程
          reject()
        } else {
          // 内部路径
          const path = redirectInfo.path || '/'
          // 页面参数
          const query = redirectInfo.query || {}
          // 不需要保留在url的参数
          const filterParams = ['openID', 'unionId', 'appId']
          // 过滤完拿到最终转向url
          filterParams.forEach((item) => {
            delete query[item]
          })
          store.commit('SET_TOKEN', data.token)
          // 直接静默登录
          store.commit('SET_USER_INFO', data)

          // 静默登录完直接重定向页面
          resolve({ path, query })
        }
      } else {
        // 微信code 授权参数
        let option = {
          // 回调地址
          redirectPath: url
        }

        try {
          // appId && authType
          const dataConfig = await getAppId()
          option.appId = dataConfig.appId
          option.authType = dataConfig.authType

          // openId
          const openId = store.getters.getOpenId
          console.log(openId, 555555555555)

          // 获取token
          const data = await getToken(openId)

          // 判断是否返回token，没有的话重新走微信授权流程
          if(!data.token) {
            wx.authPlatform(option)
          } else {
            // 直接静默登录
            store.commit('SET_TOKEN', data.token)
            store.commit('SET_USER_INFO', data)
            // 流程结束，正常进入页面
            resolve()
          }
        } catch(e) {
          // 如果没获取到appId
          if(e == 'appid') {
            return reject()
          }
          wx.authPlatform(option)
        }
      }
    } else {
      // 授权失败，去走手机号登录流程
      reject()
    }
  })
}
