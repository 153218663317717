<!-- 金刚区组件 行数类型（0-4个，1-5个）activityComponentType==='9' -->
<template>
  <div class="king-kong-area" :class="config.rowsType==1?'kingkong-list5':'kingkong-list4'">
    <div
      v-for="(item, index) in config.functionList"
      :key="index"
      @click="onJumpLink(item)"
      class="king-kong-item"
    >
      <img
        class="item-icon"
        :src="item.imageUrl"
      />
      <div class="item-name">
        {{ item.configName }}
      </div>
    </div>
  </div>
</template>

<script>

import api from '@/utils/api'

export default {
  props: {
    // 配置信息
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {

    }
  },
  methods: {
    // 跳转 linkType: 0内部路由｜1外部链接
    onJumpLink(item) {
      api.jumpLink(item)
    }
  }
}
</script>

<style lang="scss" scoped>

.kingkong-list5 {
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
}

.kingkong-list4 {
  display: grid;
  grid-template-columns: repeat(4,25%);
}

.king-kong-area {
  //display: grid;
  //grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-row-gap: 44px;
  padding: 30px 0;
  border-radius: 16px;
  margin: 30px;
  min-height: 204px;
  background: #FFFFFF;
  .king-kong-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    .item-icon {
      object-fit: cover;
      width: 96px;
      height: 96px;
      margin-bottom: 15px;
    }
    .item-name {
      font-size: 27px;
      font-weight: bold;
      color: #353535;
      text-align: center;
    }
  }
}
</style>
