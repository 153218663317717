import 'amfe-flexible'
import '@/styles/index.scss'
import Vue from 'vue'
import vant from '@/components/vant'
import component from '@/components/index'
import App from './App.vue'
import router from './router'
import store from './store'
import init from '@/utils/init'
import '@/styles/index.scss'
import auth from '@/utils/auth'
import { Toast } from 'vant'
// import { loginTIM } from '@/plugin/tencentIM/im-init'
import { loginIM } from './plugin/rong-cloud/im-init'
import 'video.js/dist/video-js.css'
import qs from 'qs'
import permission from '@/utils/permission'

init()

Vue.config.productionTip = false

Vue.use(vant)
Vue.use(component)

Vue.prototype.$onWait = new Promise(resolve => {
  Vue.prototype.$resolve = resolve
})

router.beforeEach(async(to, from, next) => {
  document.title = to.meta.title || '健康服务'
  permission(to, next)
  // // 社区编号
  // const clinicCode = store.getters.getClinicCode
  // // 免登凭证
  // const certSign = to.query.certSign || null
  // // token
  // const token = store.getters.getToken

  // if(!clinicCode) return Toast('请提供社区编号')

  // // 当url存在免登凭证且跟本地缓存的免登凭证不一致的话
  // if(certSign && (certSign != store.getters.getCertSign)) {
  //   // 保存免登凭证到本地
  //   store.commit('SET_CERTSIGN', certSign)
  //   console.log(111111111)

  //   // 通过免登凭证进行登录
  // } else if(token || to.meta.whitelist) {
  //   // 存在token或白名单页面或不存在openId情况下
  //   next()
  // } else {
  //   // 需要去登录了
  //   try {
  //     const data = await auth(to) || {}
  //     loginIM()
  //     if(data.path) {
  //       // 有回调地址的话直接强制重定向
  //       router.replace({
  //         path: data.path,
  //         query: data.query
  //       })
  //     } else {
  //       next()
  //     }
  //   } catch(err) {
  //     // 当前页面参数
  //     const query = { ...to.query }
  //     // 需要去除的页面参数
  //     const filterParams = ['openID', 'unionId', 'appId']
  //     // 过滤完拿到最终转向url
  //     filterParams.forEach((item) => {
  //       delete query[item]
  //     })

  //     router.replace({
  //       path: '/login',
  //       query: {
  //         redirectUrl: to.path,
  //         param: qs.stringify(query)
  //       }
  //     })
  //   }
  // }
})

new Vue({
  router,
  store,
  beforeCreate: function() {
    Vue.instance = this
  },
  render: (h) => h(App)
}).$mount('#app')

