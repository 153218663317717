import api from '@/api/api'

/**
 * 图片上传
 * @param {Object} param 入参
 * @param {Object} param.base64Str 文件BASE64字符串
 * @param {Object} param.fileType 文件类型（如：png、jpg）
 * @param {Object} param.saveType 文件存储类别,可用值:REFERRAL_FORM_IMAGES,TREAT_IMAGES,COMMON_FILE
 *
 */
export function postUploadFileBase64(param) {
  return api.post({
    url: '/h5/common/file/postUploadFileBase64',
    data: param,
    loading:true,
    loadingText:'上传中',
    timeout: 30000
  })
}

/**
 * 删除已经上传图片
 * @param {Object} param 入参
 * @param {String} param.fileUrl 文件地址
 *
 */
export function postDeleteFile(param) {
  return api.post({
    url: '/h5/common/file/postDeleteFile',
    data: param,
    loading:true,
    loadingText:'删除中'
  })
}

