import api from './api'
import store from '@/store'
// import { initTIM } from '@/plugin/tencentIM/im-init'
import { initIM } from '@/plugin/rong-cloud/im-init'
const { VUE_APP_MODE } = process.env

export default function() {
  // 新社区编号
  const newClinicCode = api.getUrlParam({
    key: 'clinicCode'
  })

  // 老社区编号
  const clinicCode = store.getters.getClinicCode

  // 存在新社区编号的情况下
  if(newClinicCode && (newClinicCode != clinicCode)) {
    // 清除当前用户信息
    store.commit('CLEAR_USER_INFO', [`CLINIC_CODE_${VUE_APP_MODE}`])
    store.commit('SET_CLINIC_CODE', newClinicCode)
    sessionStorage.removeItem('redirectInfo')
  }

  // 始化im
  // initTIM()
  initIM()
}
