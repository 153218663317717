
const DEBUG = process.env.VUE_APP_MODE === 'DEV' || process.env.VUE_APP_MODE === 'STA' // true表示开发版本，false表示对外版本

export default {
  /**
   * @type {boolean} true | false
   * @description true表示开发版本，false表示对外版本
   */
  DEBUG: DEBUG,

  /**
   * @type {Number}
   * @description 腾讯IMID
   */
  IM_SDKID: DEBUG ? 1400505295 : 1400505295
}
