<template>
  <!-- 标签组件 -->
  <div class="tag-page">
    <!-- tabs -->
    <van-tabs
      class="tabs"
      v-model="active"
      swipe-threshold="0"
    >
      <van-tab
        v-for="item in config.labelList"
        :key="item.labelId"
        :name="item.labelId"
        :title="item.title"
      ></van-tab>
    </van-tabs>
    <!-- 文章专区 -->
    <article-list
      ref="article-list"
      v-if="config.labelList.length"
      v-bind.sync="currentArticleConfig"
    ></article-list>
  </div>
</template>

<script>

import articleList from '@/views/components/article-list/index.vue'

export default {
  components: {
    articleList
  },
  props: {
    // 配置信息
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      active: null, // 当前tab
      currentArticleConfig: {} // 当前文章
    }
  },

  // computed: {
  //   // 获取文章配置信息
  //   getArticleConfig() {
  //     // 找到当前项
  //     const item = this.config.labelList.find((item) => {
  //       return item.labelId == this.active
  //     })

  //     // 配置项
  //     let config = {}

  //     switch (item?.labelType) {
  //       case 1: // 图片类型
  //         config = {
  //           config: {
  //             imageUrl: item.imageUrl
  //           },
  //           showMore: false,
  //           articleType: '2'
  //         }
  //         return config
  //       case 2: // 文章类型
  //         config = {
  //           config: {
  //             clinicZoneId: item.clinicZoneId
  //           },
  //           isLoad: true
  //         }
  //         return config
  //       default:
  //         return config
  //     }
  //   }
  // },

  watch: {
    // 监听tab变化
    active: {
      handler(newValue, oldValue) {
        // 找到当前项
        const item = this.config.labelList.find((item) => {
          return item.labelId == this.active
        })
        // console.log(item)
        switch (item?.labelType) {
          case 1: // 图片
            this.currentArticleConfig = {
              config: {
                imageUrl: item.imageUrl
              },
              showMore: false,
              articleType: '2'
            }
            return
          case 2: // 文章类型
            this.currentArticleConfig = {
              config: {
                clinicZoneId: item.clinicZoneId
              },
              isLoad: true
            }
            this.$nextTick(() => {
              this.$refs['article-list'].reset()
            })
            return
          default:
            return
        }
      }
    }
  },

  methods: {

  }

}
</script>

<style lang="scss" scoped>
.tag-page {
  margin: 50px 0 30px 0;
  .tabs {
    padding: 0 30px;
    margin-bottom: 20px;
    ::v-deep .van-tabs__wrap {
      // height: auto;
      .van-tabs__nav {
        padding-left: 0;
        padding-right: 0;
        background: none;
        .van-tabs__line {
          width: 40px;
          height: 10px;
          background: #353535;
          border-radius: 5px;
        }
        .van-tab {
          flex: none;
          font-size: 32px;
          color: #353535;
          padding: 0;
          &:not(:last-child) {
            margin-right: 30px;
          }
          &.van-tab--active {
            font-size: 40px;
            font-weight: bold;
          }
        }
      }
    }
  }
}
</style>
