<template>
  <div class="header">
    <!-- 头部 -->
    <van-nav-bar
      fixed
      placeholder
      class="nav-bar"
      z-index="99"
    >
      <!-- 左侧 -->
      <template #left>
        <div class="header-left">
          <img
            class="avatar"
            :src="getToken ? (getUserInfo.avatarUrl || defaultAvatar) : defaultAvatar"
          >
          <div class="base-info">
            <!-- 已登录展示 -->
            <div
              class="user"
              v-if="getToken"
            >
              <div class="user-name">
                {{ getUserInfo.userName }}
              </div>
              <div class="operate">
                <slot name="left">
                  <div @click="goHome">点击回到首页</div>
                </slot>
              </div>
            </div>
            <div
              v-else
              class="login"
              @click="login"
            >点击登录</div>
          </div>
        </div>
      </template>
      <!-- 右侧 -->
      <template #right>
        <div class="header-right">
          <slot name="right">
            <div
              class="msg-box"
              @click="goMsgList"
            >
              <i :class="['icon', unread ? 'unread' : '']"></i>
              <span class="text">
                {{ unread ? '新消息' : '消息'}}
              </span>
            </div>
          </slot>
        </div>
      </template>
    </van-nav-bar>
  </div>
</template>

<script>

import { mapGetters } from 'vuex'
import auth from '@/utils/auth'
import wx from '@/utils/wx-utils'
import api from '@/utils/api'
import { getUnReadMsgCount } from '@/api/chat'
import defaultAvatar from '@/assets/avatar.png'
import { loginIM } from '@/plugin/rong-cloud/im-init'
import qs from 'qs'

export default {
  data() {
    return {
      // 默认头像
      defaultAvatar,
      // 是否有未读消息
      unread: false,
      // 页面查询参数
      pageQuery: {}
    }
  },

  computed: {
    ...mapGetters([
      'getToken',
      'getUserInfo'
    ])
  },

  created() {
    this.init()
  },

  methods: {

    // 初始化
    async init() {
      this.pageQuery = { ...this.$route.query }
      const filterParams = ['openID', 'unionId', 'appId']
      // 过滤完拿到最终转向url
      filterParams.forEach((item) => {
        delete this.pageQuery[item]
      })

      const openId = api.getUrlParam({
        key: 'openID'
      })
      if (wx.isWeixin() && openId && this.$route.meta.whitelist) {
        try {
          const data = await auth(this.$route)
          loginIM()
          // 查询参数
          const query = qs.stringify(data.query)
          // 转向url
          const url = `${window.location.origin}${window.location.pathname}#${data.path}${query ? `?${query}` : ''}`
          // 通过replaceState强转页面
          if (window.history) {
            window.history.replaceState(null, document.title, url)
            window.history.go(0)
          } else {
            window.location.replace(url)
          }
        } catch (err) {
          // 走手机号登录流程
          this.$router.replace({
            path: '/login',
            query: {
              redirectUrl: this.$route.path,
              param: qs.stringify(this.pageQuery)
            }
          })
        }
      }
      this.getUnReadMsgCount()
    },

    // 登录
    async login() {
      try {
        const data = await auth(this.$route)
        if (!data?.path) {
          // 直接静默登录
          this.$router.go(0)
        }
      } catch (err) {
        // 走手机号登录流程
        this.$router.replace({
          path: '/login',
          query: {
            redirectUrl: this.$route.path,
            param: qs.stringify(this.pageQuery)
          }
        })
      }
    },

    // 获取未读消息数
    getUnReadMsgCount() {
      if (this.$store.getters.getToken) {
        getUnReadMsgCount().then((res) => {
          if (res.data) this.unread = true
        }).catch((err) => {

        })
      }
    },

    // 前往消息列表
    goMsgList() {
      api.jumpPage('chat-list')
    },

    // 前往首页
    goHome() {
      api.jumpPage('home', {
        clinicTopicCode: '',
        source: 'home'
      })
    },

    // 前往个人中心
    goMy() {
      api.jumpPage('my')
    }
  }
}
</script>

<style lang="scss" scoped>
.header {
  .nav-bar {
    ::v-deep .van-nav-bar__content {
      height: 140px;
    }
  }
  .header-left {
    display: flex;
    align-items: center;
    .avatar {
      height: 100px;
      width: 100px;
      border-radius: 50%;
      margin-right: 20px;
      object-fit: cover;
    }
    .base-info {
      .user {
        text-align: left;
        font-size: 36px;
        .user-name {
          color: #333;
          font-weight: bold;
        }
        .operate {
          color: #666;
          font-size: 28px;
        }
      }
      .login {
        font-size: 36px;
        color: #333;
        font-weight: bold;
      }
    }
  }
  .header-right {
    .msg-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      .icon {
        width: 38px;
        height: 38px;
        background-image: url('~@/assets/icons/msg.png');
        background-size: cover;
        margin-bottom: 5px;
        &.unread {
          background-image: url('~@/assets/icons/msg-unread.png');
        }
      }
      .text {
        color: #333;
        font-size: 26px;
      }
    }
  }
}
</style>
