export default [
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/index'),
    meta: {
      title: '医疗风险告知及知情同意书',
      whitelist: true
    }
  }
]
