<template>
  <div>
    <van-uploader
      v-model="arrImgs"
      preview-image
      :after-read="afterRead"
      multiple
      accept="image/*"
      @delete="deleteImg"
    >
      <div class="img-upload-box">
        <van-icon name="photograph" />
      </div>
    </van-uploader>
  </div>
</template>

<script>
import api from '@/utils/api'
import { postUploadFileBase64, postDeleteFile } from '@/api/common'

export default {

  name: 'ZUploaderImg',

  props: {
    value: {
      type: Array,
      value: ''
    }
  },

  data() {
    return {
      arrUploadImgs: [] // 上传图片数组
    }
  },

  computed: {
    arrImgs: {
      get() {
        return this.value
      },
      set(v) { }
    }
  },

  methods: {

    // 更新value
    onEmitInput() {
      this.$emit('input', this.arrUploadImgs)
    },

    // 上传图片读取完成后的回调函数
    afterRead(file) {
      // console.log('filelist', file)
      file.forEach(item => {
        let index = item.file.type.indexOf('/')
        let type = item.file.type.substr(index + 1, item.file.type.length)
        postUploadFileBase64({
          base64Str: item.content,
          fileType: type,
          saveType: 'TREAT_IMAGES'
        }).then(res => {
          let data = res.data
          data.url = data.fileUrl
          this.arrUploadImgs.push({ ...data })
          // console.log('upup', this.arrUploadImgs)
          this.onEmitInput()
        }).catch(err => {
          api.toast(err)
        })
      })
    },
    // 删除图片
    deleteImg(file, detail) {
      postDeleteFile({
        fileUrl: this.arrUploadImgs[`${detail.index}`].fileUrl
      }).then(res => {
        // 服务端删除成功，将本地数组项删除，确保数组与云端一致
        this.arrUploadImgs.splice(`${detail.index}`, 1)
        this.onEmitInput()
      }).catch(err => {
        api.toast(err)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.img-upload-box {
  position: relative;
  height: 80px !important;
  width: 80px !important;
  line-height: 80px !important;
  text-align: center !important;
  background-color: #f9f9f9 !important;
}
</style>
