<template>
  <div>
    <van-overlay :show=" show " @click="isOverlayHide?'onHide':''" z-index="999">
      <div class="pop">
        <div class="title" v-if="title">{{title}}</div>
        <slot name="title"></slot>
        <div class="content" v-if="content">{{content}}</div>
        <slot name="content"></slot>
        <div class="z-btn-wrap" v-if="showCancle && showSure">
          <div class="z-btn-twe" :class="btnLeftClass?'btnLeftClass':'z-btn-grey'" @click="onCancle">{{cancleText}}</div>
          <div class="z-btn-twe" @click="onSure">{{sureText}}</div>
        </div>
        <div class="z-btn-wrap" v-else>
          <div class="z-btn-one z-btn-grey" @click="onCancle" v-if="showCancle">{{cancleText}}</div>
          <div class="z-btn-one" @click="onSure" v-if="showSure">{{sureText}}</div>
        </div>
      </div>
    </van-overlay>
  </div>
</template>

<script>
export default {
  name: 'ZPop',
  props: {
    showPop: {
      type: Boolean,
      default: false
    },

    backHide: {
      type: Boolean,
      default: true
    },

    title: {
      type: String,
      optionalTypes: [String, Number],
      default: ''
    },

    content: {
      type: String,
      optionalTypes: [String, Number],
      default: ''
    },

    showCancle: {
      type: Boolean,
      default: true
    },

    showSure: {
      type: Boolean,
      default: true
    },

    cancleText:{
      type: String,
      default: '取消'
    },

    sureText:{
      type: String,
      default: '确定'
    },

    btnLeftClass: {
      type: Boolean,
      default: false
    },

    isOverlayHide: {
      type: Boolean,
      default: true
    },

    hide: {
      type: Function,
      default: null
    },
    cancle: {
      type: Function,
      default: null
    },
    sure: {
      type: Function,
      default: null
    }
  },

  data() {
    return {
      show: true
    }
  },

  watch: {
    showPop: {
      handler(newValue) {
        // console.log(newValue)
        this.show = newValue
      }
    }
  },

  methods: {

    onHide() {
      this.show = false
      this.hide()
    },

    onCancle(){
      this.cancle()
    },

    onSure(){
      this.sure()
    }
  }
}
</script>

<style lang="scss" scoped>

.pop{
  background: #ffffff;
  border-radius: 20px;
  width: 84%;
  position: absolute;
  top: 50%;
  left: 8%;
  transform: translateY(-50%);
  overflow: hidden;
  padding: 30px 30px 40px 30px;
  z-index: 999;
}

.title{
  display: flex;
  justify-content: center;
  margin: 10px 0 30px 0;
  font-weight: bold;
  font-size: 34px;
}

.content{
  display: flex;
  justify-content: center;
  margin: 30px 0;
  font-size: 34px;
  color: gray;
}

.z-btn-wrap{
  display: flex;
  justify-content: space-around;
  padding: 30px 0 0 0;
}

.z-btn-one{
  width: 368px;
  height: 80px;
  background: #4085D5;
  border-radius: 8px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.z-btn-twe{
  padding: 0 60px;
  height: 80px;
  min-width: 250px;
  background: #4085D5;
  border-radius: 8px;
  color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 45px;
}

.z-btn-grey {
  background: #F1F1F1!important;
  color: #353535!important;
}
</style>
