<!-- 公告组件 activityComponentType==='1' -->
<template>
  <div class="notice">
    <van-notice-bar scrollable left-icon="volume-o" :text="config.content" mode="link" @click="onShowNotice(config)" />
  </div>
</template>

<script>

import api from '@/utils/api'

export default {
  props: {
    // 配置信息
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {

    }
  },
  computed: {

  },
  methods: {
    // 显示公共栏
    onShowNotice(item) {
      this.$dialog.confirm({
        message: item.content,
        showCancelButton: false,
        confirmButtonText: '确定',
        confirmButtonColor: '#4767AC'
      }).then(() => {
        // on confirm
      }).catch(() => {
        // on cancel
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.notice {
  margin-bottom: 20px;

}
</style>
