<template>
  <div
    class="card"
    @click="onJumpDetail"
  >
    <div class="cover-box">
      <img
        class="cover"
        :src="config.articleCover"
      >
      <!-- 是否为视频类型 -->
      <div
        v-if="config.contentType == 1"
        class="mask"
      ></div>
    </div>
    <div class="card-info">
      <div class="type">
        {{ typeObj[config.contentType] }}
      </div>
      <div class="title overflow-hidden">
        {{ config.articleTitle }}
      </div>
    </div>
  </div>
</template>

<script>

import api from '@/utils/api'

export default {
  props: {
    // 配置信息
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {
      // 类型名称
      typeObj: {
        1: '视频',
        2: '音频',
        3: '图文'
      }
    }
  },
  computed: {

  },
  methods: {
    // 文章类型(0H5文章，1公众号文章)
    onJumpDetail() {
      let articleType = this.config.articleType
      if (articleType === 0) {
        return api.jumpPage('article-detail', { ...this.config })
      }
      if (articleType === 1) {
        api.jumpLink({
          linkType: this.config.articleType,
          linkSrc: this.config.articleSrc
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  &:not(:last-child) {
    margin-bottom: 40px;
  }
  .cover-box {
    height: 240px;
    position: relative;
    margin-bottom: 20px;
    .cover {
      width: 100%;
      height: 100%;
      border-radius: 16px;
    }
    .mask {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      background: #707070;
      opacity: 0.2;
      border-radius: 16px;
      &::before {
        content: '';
        display: inline-block;
        width: 0;
        height: 0;
        border-top: 35px solid transparent;
        border-right: 35px solid transparent;
        border-left: 60px solid #fff;
        border-bottom: 35px solid transparent;
        position: absolute;
        top: 50%;
        left: 50%;
        margin-top: -30px;
        margin-left: -30px;
      }
    }
  }
  .card-info {
    display: flex;
    align-items: center;
    .type {
      padding: 2px 14px;
      font-size: 26px;
      color: #666;
      border-radius: 10px;
      border: 1px solid #dedede;
      margin-right: 10px;
      flex-shrink: 0;
    }
    .title {
      font-size: 28px;
      color: #353535;
    }
  }
}
</style>
