// 分页
export const countDown = {
  data() {
    return {
      canSend: true,
      codeTime: 60,
      codeTimeT: null
    }
  },

  methods: {

    sendCode() {
      if (this.canSend) {
        this.canSend = false
        this.codeTimeT = setInterval(() => {
          this.codeTime--
          if (this.codeTime <= 0) {
            this.canSend = true
            clearInterval(this.codeTimeT)
            this.codeTime = 60
          }
        }, 1000)
      }
    }
  }

}
