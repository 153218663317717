<!--标题组件 activityComponentType==='3'-->
<script src="../../../utils/api.js"></script>
<template>
  <!-- 标题组件 -->
  <div class="title-area">
    <!-- 主体内容 -->
    <div class="main-box">
      <!-- 主标题 -->
      <div class="main" :style="`font-size: ${config.titleFont||'20'}px; color: ${config.titleColor||'#353535'};`">{{ config.title }}</div>
      <!-- 副标题 -->
      <div v-if="config.subTitleFlag == 1" class="sub overflow-hidden" :style="`font-size: ${config.subTitleFont||'16'}px; color: ${config.titleColor||'#ABABAB'};`">
        {{ config.subTitle }}
      </div>
    </div>
    <!-- 更多 -->
    <span
      v-if="config.moreFlag==1"
      class="more"
      @click="onJumpLink(config)"
    >
      {{ config.moreCopy }}<van-icon name="arrow" />
    </span>
  </div>
</template>

<script>

import api from '@/utils/api'

export default {
  props: {
    // 配置信息
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {

    }
  },
  methods: {
    // 跳转 linkType: 0内部路由｜1外部链接
    onJumpLink(item) {
      api.jumpLink(item)
    }
  }
}
</script>

<style lang="scss" scoped>
.title-area {
  display: flex;
  align-items: center;
  padding: 0 30px;
  color: #353535;
  margin: 50px 0 30px 0;
  .main-box {
    display: flex;
    align-items: center;
    flex: 1;
    width: 0;
    .main {
      font-size: 40px;
      font-weight: bold;
      margin-right: 10px;
      flex-shrink: 0;
    }
    .sub {
      font-size: 32px;
    }
  }
  .more {
    flex-shrink: 0;
    font-size: 30px;
  }
}
</style>
