<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script>
// 解决android微信字体大小调整导致页面布局错误
(() => {
  if (
    typeof WeixinJSBridge == 'object' &&
    typeof WeixinJSBridge.invoke == 'function'
  ) {
    handleFontSize()
  } else {
    if (document.addEventListener) {
      document.addEventListener('WeixinJSBridgeReady', handleFontSize, false)
    } else if (document.attachEvent) {
      document.attachEvent('WeixinJSBridgeReady', handleFontSize)
      document.attachEvent('onWeixinJSBridgeReady', handleFontSize)
    }
  }
  function handleFontSize() {
    // 设置网页字体为默认大小
    WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
    // 重写设置网页字体大小的事件
    WeixinJSBridge.on('menu:setfont', () => {
      WeixinJSBridge.invoke('setFontSizeCallback', { fontSize: 0 })
    })
  }
})()

// import { loginTIM } from '@/plugin/tencentIM/im-init'
import { loginIM } from '@/plugin/rong-cloud/im-init'

export default {

  beforeRouteEnter(to, from, next) {
    console.log(1111111)
    next()
  },

  data() {
    return {

    }
  },

  created() {
    this.init()
  },

  methods: {
    // 初始化
    init() {
      const token = this.$store.getters.getToken

      // 监听im sdk 准备状态
      this.$event.on('imReady', () => {
        this.$resolve()
      })

      // 获取登录状态直接登录im
      if (token) {
        // loginTIM()
        loginIM()
      }
    }
  }

}
</script>

<style lang="scss">
// 解决ios微信字体大小调整导致页面布局错误
body {
  -webkit-text-size-adjust: 100% !important;
}
</style>
