<template>
  <div>
    <van-action-sheet
      v-model="show"
      safe-area-inset-bottom
      close-on-click-overlay
      title="我的优惠券"
      @close="close"
      :style="{background: background}"
    >
      <z-empty
        v-if="!arrCoupon?.length"
        title="您还没有优惠券"
        :src=" require('@/assets/empty_rights.png')"
      ></z-empty>
      <div
        class="coupon-wrap"
        v-else
      >
        <div class="coupon-box">
          <div
            v-for="(item,index) in arrCoupon"
            :key="item.id"
            :item="item"
            @click="selectCoupon(item,index)"
          >
            <z-coupon-item
              :dataCoupon="item"
              :showCheck="true"
              :isCheck="item.isCheck"
              :show-use="false"
            ></z-coupon-item>
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>

<script>

export default {
  name: 'ZCouponPop',

  props: {
    background: {
      type: String,
      default: '#f9f9f9'
    },
    // 是否展示优惠券列表
    showValue: {
      type: Boolean,
      default: false
    },
    arrCoupon: {
      type: Array,
      default: () => ([])
    }
  },

  data() {
    return {
      show: false,
      dataCurrentCoupon: {}
    }
  },

  watch: {
    showValue: {
      handler(newValue) {
        // console.log(newValue)
        this.show = newValue
      }
    }
  },

  methods: {
    // 选中优惠券
    selectCoupon(item, index) {
      let selectCoupon = this.arrCoupon[`${index}`]
      if (selectCoupon.isCheck) {
        selectCoupon.isCheck = false
        this.$emit('selectedCoupon', {})
        this.dataCurrentCoupon = selectCoupon
      } else {
        this.arrCoupon.forEach((item, index) => {
          item.isCheck = false
        })
        selectCoupon.isCheck = true
        this.$emit('selectedCoupon', selectCoupon)
        this.dataCurrentCoupon = selectCoupon
      }

      this.show = false
      // eslint-disable-next-line vue/no-mutating-props
    },

    // 关闭弹窗
    close() {
      this.$emit('close')
    }
  }

}
</script>

<style lang="scss" scoped>
::v-deep {
  .van-action-sheet__header {
    font-weight: bold;
    font-size: 38px;
    color: #353535;
    text-align: left;
    padding-left: 30px;
  }

}
.coupon-wrap {
  box-sizing: border-box;
  //background: #f9f9f9;
  overflow-y: scroll;
  padding: 30px;
  height: 600px;
}
</style>
