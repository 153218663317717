<template>
  <div class="doc-wrap">
    <template v-if="config.rowType==0">
      <z-doc-cate :arrDoc="config.arrDoc"></z-doc-cate>
    </template>
    <div class="m-doc-plate2" v-else-if=" config.rowType==1 ">
      <div class="item" v-for="(itemSub,index) in config.arrDoc" @click="onJumpDoc(itemSub)">
        <img class="img" :src="itemSub.doctorImage"/>
        <div class="title">{{itemSub.doctorName}} {{itemSub.doctorTitleName}}</div>
        <div class="title-sub">{{itemSub.organizationName}}</div>
        <div class="title-sub">{{itemSub.deptName}}</div>
      </div>
    </div>
    <!-- 横向 -->
    <template v-else>
      <div class="m-doc-plate3"  v-if="config.arrDoc.length>0">
        <div class="item" v-for="(itemSub,index) in config.arrDoc" @click="onJumpDoc(itemSub)">
          <div class="item-top">
            <img class="img" :src="itemSub.doctorImage||require('@/assets/doctor-default.png')"/>
            <div class="item-top_title"><span class="z-line1">{{itemSub.deptName}}</span></div>
          </div>
          <div class="title">{{itemSub.doctorName}}｜{{itemSub.doctorTitle}}</div>
          <!-- <div class="title-sub"></div> -->
          <div class="title-three">{{itemSub.organizationName}}</div>
        </div>
      </div>
    </template>
    <div v-if=" config.rowType==0 || config.rowType==1 " class="m-doc-more" @click="onJumpDocMore(config)">
      <span>更多医生</span>
      <van-icon name="arrow" class="icon" />
    </div>
  </div>
</template>

<script>

import api from '@/utils/api'

export default {
  props: {
    // 配置信息
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {

    }
  },
  methods: {
    // 专病组
    onJumpDoc(item) {
      api.jumpPage('doctor-home', {
        doctorCode: item.doctorCode
      })
    },

    onJumpDocMore(item){
      api.jumpPage('doctor-list', {
        ...item
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.doc-wrap {
  margin: 50px 0 30px 0;

  .m-doc-plate2{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0 30px;
  }

  .m-doc-plate2 .item{
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-basis: 48.5%;
    min-height: 360px;
    background: #ffffff;
    margin: 10px 0;
    border-radius: 8px;
    padding: 30px 20px;
  }

  .m-doc-plate2 .img{
    object-fit: cover;
    width: 120px;
    height: 120px;
    border-radius: 100%;
    background: #fafafa;
  }

  .m-doc-plate2 .title{
    font-size: 32px;
    color: #333333;
    margin-top: 20px;
    font-weight: bold;
  }

  .m-doc-plate2 .title-sub{
    font-size: 28px;
    color: #666666;
    margin-top: 20px;
    text-align: center;
  }

  .m-doc-plate1{
    margin: 0 -20px;
    display: flex;
    justify-content: left;
    align-items: flex-start;
    padding: 40px 30px;
    background-color: #ffffff;
    overflow: hidden;
  }

  .m-doc-tx{
    width: 120px;
    height: 120px;
    margin-right: 16px;
    border-radius: 50%;
    flex: none;
  }

  .m-doc-main{
    flex: auto;
  }

  .m-doc-top{
    font-size: 36px;
    font-weight: 600;
    color: #000000;
    display: flex;
    align-items: center;
    margin-bottom: 14px;
  }

  .m-doc-top .name{
    font-weight: 800;
    margin-right: 20px;
    font-size: 40px;
    flex: none;
  }

  .m-doc-top .rank{
    font-size: 32px;
    font-weight: 400;
    color: #666666;
  }

  .m-doc-level .level{
    display: inline-block;
    text-align: center;
    padding: 2px 6px 2px 6px;
    background: #F8BA4F;
    border-radius: 6px;
    font-size: 22px;
    color: #FFFFFF;
    flex: none;
    margin-left: 12px;
    margin-right: 12px;
    height: 40px;
  }

  .m-doc-level{
    display: flex;
    flex-direction: row;
    font-size: 28px;
    font-weight: 500;
    line-height: 36px;
    color: #333333;
  }

  .m-doc-good{
    line-height: 46px;
    font-size: 28px;
    font-weight: 400;
    color: #333333;
    margin: 26px 0;
  }

  .m-doc-service{
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
  }

  .m-doc-service .label{
    background: #F9F9F9;
    border: 1px solid #F1F1F1;
    border-radius: 40px;
    text-align: center;
    font-size: 28px;
    box-sizing: border-box;
    font-weight: 400;
    margin: 30px 30px 0 0;
    padding: 15px 22px;
    display: flex;
    align-items: center;
    height: 78px;
    white-space: nowrap;
    justify-content: center;
    min-width: 220px;
    position: relative;
  }

  .m-doc-service .price{
    font-size: 28px;
    font-weight: bolder;
    color:#03D6D7;
  }

  .m-doc-service .unit{
    font-size: 26px;
    color: #666666;
    font-weight: 400;
  }

  .m-doc-more{
    /* margin: 0 -20px 0 -20px; */
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    color: #333333;
  }

  .m-doc-more .icon{
    font-size: 32px;
    line-height: 32px;
    margin-top: 2px;
  }

  .m-doc-plate3 {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
    margin: 0px 30px 20px 25px;
    /* background: #ffffff; */
    min-height: 350px;
  }

  .m-doc-plate3::-webkit-scrollbar{
    width: 0;
    height: 0;
    color: transparent;
  }

  .m-doc-plate3 .item{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 260px;
    min-height: 300px;
    border-radius: 16px;
    padding: 30px 5px;
    flex: none;
    /* background-image: linear-gradient(rgba(235, 246, 255),white 35%); */
    background: #FFFFFF;
    text-align: center;
    /* box-shadow: 10px 10px 25px rgba(181, 181, 181, 0.3); */
    box-shadow:rgba(181, 181, 181, 0.15) 0px 10px 25px;
    margin: 0px 10px;
  }

  .m-doc-plate3 .item-main{
    position: absolute;
    top: 0;
    left: 0;
  }

  .m-doc-plate3 .img{
    object-fit: cover;
    width: 142px;
    height: 142px;
    border-radius: 100%;
    border: 1.5px solid rgba(181, 181, 181, 0.3);
  }

  .m-doc-plate3 .title{
    font-size: 26px;
    color: #333333;
    font-weight: bold;
  }

  .m-doc-plate3 .title-sub{
    font-size: 24px;
    color: #666666;
    margin-top: 26px;
  }

  .m-doc-plate3 .title-three{
    font-size: 22px;
    color: #666666;
    margin-top: 5px;
  }

  .m-doc-plate3 .item-top{
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 34px;
  }

  .m-doc-plate3 .item-top_title{
    background-color: #4085D5;
    color: #FFFFFF;
    font-size: 26px;
    border-radius: 20px;
    min-width: 129px;
    max-width: 100%;
    height: 39px;
    margin-top: -20px;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    word-break: break-all;
  }

}

</style>
