<template>
  <!-- 瓷片区 -->
  <div class="chip-area">
    <div
      v-for="(item, index) in config.list"
      :key="index"
      class="chip-item"
      @click="onJumpLink(item)"
    >
      <img
        class="chip-img"
        :src="item.imageUrl"
      />
      <div class="chip-name">
        {{ item.configName }}
      </div>
    </div>
  </div>
</template>

<script>
import api from '@/utils/api'
export default {
  props: {
    // 配置信息
    config: {
      type: Object,
      default: () => {
        return {}
      }
    }
  },
  data() {
    return {

    }
  },
  methods: {
    // 跳转 linkType: 0内部路由｜1外部链接
    onJumpLink(item) {
      api.jumpLink(item)
    }
  }
}
</script>

<style lang="scss" scoped>
.chip-area {
  padding: 30px 0px;
  margin: 30px;
  background: #FFFFFF;
  border-radius: 16px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-column-gap: 20px;
  .chip-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    .chip-img {
      width: 118px;
      height: 118px;
      object-fit: cover;
      margin-bottom: 20px;
    }
    .chip-name {
      font-size: 36px;
      font-weight: bold;
    }
  }
}
</style>
